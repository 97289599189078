@media (max-width: 1366px) {
    .wrapper_archive_statistics {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 190px 0 30px 0;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .title {
            display: flex;
            font-size: 30px;
            color: $light;
            margin-bottom: 10px;
            font-family: PLAY-BOLD;
            justify-content: center;
        }

        .blocks_matches {
            display: flex;
            height: 220px;
            margin-bottom: 10px;
            width: 100%;
            justify-content: center;

            .block {
                display: flex;
                height: 100%;
                width: 15%;
                margin: 10px 5px 20px 5px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_block_matches {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_wins {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_draw {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .value {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $light;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value2 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $green;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value3 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $gray;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value4 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $pink;
                    font-size: 50px;
                    padding-top: 10px;
                }
            }

            .big_block {
                display: flex;
                height: 100%;
                width: 40%;
                margin: 10px 10px 20px 0px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title_block_big_block {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    display: flex;
                    height: 16px;
                    color: $light;
                    justify-content: center;
                    font-family: PLAY-REGULAR;
                }

            }
        }

        .container {
            display: flex;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 0px;
            background-color: $gray_black;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .slider {
                width: 100%;
                height: 100%;
                min-height: 400px;
                padding-right: 10px;
                padding-top: 10px;

                .container_slider_css {
                    width: 100%;
                    height: 100%;
                    min-height: 600px;
                    overflow: hidden;
                    position: relative;
                    border-radius: 6px;
                }

                .photo_slider_css {
                    position: absolute;
                    animation: round 16s infinite;
                    opacity: 0;
                    width: 100%;
                }

                @keyframes round {
                    25% {
                        opacity: 1
                    }

                    40% {
                        opacity: 0
                    }
                }

                img:nth-child(1) {
                    animation-delay: 12s;
                }

                img:nth-child(2) {
                    animation-delay: 8s
                }

                img:nth-child(3) {
                    animation-delay: 4s
                }

                img:nth-child(4) {
                    animation-delay: 0
                }

                @media(min-width:0px) and (max-width:320px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }

                @media(min-width:321px) and (max-width:480px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }
            }

            .blocks {
                display: flex;
                width: 100%;
                flex-direction: column;
                height: 400px;

                .blocks_row:first-child {
                    padding-bottom: 10px;
                }

                .blocks_row {
                    display: flex;
                    width: 100%;
                    height: 50%;

                    .block {
                        display: flex;
                        height: 100%;
                        width: 50%;
                        margin: 10px 10px 10px 0px;
                        background-color: rgba(0, 0, 0, 0.4);
                        border-radius: 6px;
                        justify-content: start;
                        flex-direction: column;
                        align-items: center;

                        .title {
                            display: flex;
                            background-color: $dark_light;
                            border-radius: 6px;
                            font-family: PLAY-BOLD;
                            margin: 20px 20px 10px 20px;
                            font-family: PLAY-BOLD;
                            justify-content: center;
                            font-size: 16px;
                            width: 70%;
                            height: 25px;
                            color: $light;
                            align-items: center;
                        }

                        .text {
                            justify-content: center;
                            font-size: 16px;
                            color: $light;
                            font-family: PLAY-REGULAR;
                            padding-top: 15px;
                        }

                        ul {
                            font-family: PLAY-REGULAR;
                            height: 90%;
                            padding-top: 15px;
                            color: $light;
                            font-size: 16px;
                            width: 70%;

                            li {
                                display: flex;
                                padding: 5px;
                                justify-content: space-between;

                                .number {
                                    display: flex;
                                    justify-content: flex-start;
                                    width: 20px;
                                }

                                .player {
                                    display: flex;
                                }

                                .counter {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 30px;
                                }
                            }
                        }

                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;

            .block {
                display: flex;
                height: 220px;
                width: 33%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 60px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 60px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 60px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }

                    .img {
                        display: flex;
                        width: 60%;
                        height: 150px;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 150px;
                            padding-left: 20px;

                        }
                    }
                }
            }
        }

        .best_players {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            flex-direction: column;

            .block_row {
                display: flex;
                height: 220px;
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;

                .block {
                    display: flex;
                    height: 220px;
                    width: 25%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 20px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 70px;
                        font-family: PLAY-BOLD;
                    }


                    .text {
                        display: flex;
                        color: $light;
                        font-size: 24px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

            .block_row2 {
                display: flex;
                height: 220px;
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;

                .block {
                    display: flex;
                    height: 220px;
                    width: 33%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    background-size: cover;
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 20px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 70px;
                        font-family: PLAY-BOLD;
                    }

                    .text {
                        display: flex;
                        color: $light;
                        font-size: 24px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

        }

        .all_goals {
            display: flex;
            height: 100%;

            .block {
                display: flex;
                height: 1050px;
                width: 25%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 20px 10px 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    font-size: 16px;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    justify-content: center;
                    font-size: 16px;
                    color: $light;
                    font-family: PLAY-REGULAR;
                    padding-top: 15px;
                }

                ul {
                    font-family: PLAY-REGULAR;
                    height: 90%;
                    padding-top: 15px;
                    color: $light;
                    font-size: 16px;
                    width: 90%;

                    li {
                        display: flex;
                        padding: 5px;
                        justify-content: space-between;

                        .number {
                            display: flex;
                            justify-content: flex-start;
                            width: 20px;
                        }

                        .player {
                            display: flex;
                        }

                        .counter {
                            display: flex;
                            justify-content: flex-end;
                            width: 30px;
                        }
                    }
                }

            }
        }
    }
}

@media (max-width: 1024px) {
    .wrapper_archive_statistics {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 180px 0 30px 0;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .title {
            display: flex;
            font-size: 30px;
            color: $light;
            margin-bottom: 10px;
            font-family: PLAY-BOLD;
            justify-content: center;
        }

        .blocks_matches {
            display: flex;
            height: 480px;
            margin-bottom: 0px;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 220px;
                width: 23%;
                margin: 10px 5px 10px 5px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_block_matches {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_wins {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_draw {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .value {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $light;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value2 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $green;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value3 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $gray;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value4 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $pink;
                    font-size: 50px;
                    padding-top: 10px;
                }
            }

            .block:first-child {
                margin: 10px 5px 10px 5px;
            }

            .big_block {
                display: flex;
                height: 100%;
                width: 96%;
                margin: 0px 10px 20px 5px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title_block_big_block {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    display: flex;
                    height: 16px;
                    color: $light;
                    justify-content: center;
                    font-family: PLAY-REGULAR;
                }

            }
        }

        .container {
            display: flex;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 0px;
            background-color: $gray_black;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .slider {
                width: 100%;
                height: 100%;
                padding-right: 10px;
                padding-top: 10px;
                display: none;

                .container_slider_css {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    border-radius: 6px;
                }

                .photo_slider_css {
                    position: absolute;
                    animation: round 16s infinite;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    min-height: 0px;
                }

                @keyframes round {
                    25% {
                        opacity: 1
                    }

                    40% {
                        opacity: 0
                    }
                }

                img:nth-child(1) {
                    animation-delay: 12s;
                }

                img:nth-child(2) {
                    animation-delay: 8s;
                }

                img:nth-child(3) {
                    animation-delay: 4s;
                }

                img:nth-child(4) {
                    animation-delay: 0;
                }

                @media(min-width:0px) and (max-width:320px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }

                @media(min-width:321px) and (max-width:480px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }
            }

            .blocks {
                display: flex;
                width: 100%;
                flex-direction: column;
                height: 400px;

                .blocks_row:first-child {
                    padding-bottom: 10px;
                }

                .blocks_row {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .block {
                        display: flex;
                        height: 100%;
                        width: 50%;
                        margin: 10px 10px 10px 0px;
                        background-color: rgba(0, 0, 0, 0.4);
                        border-radius: 6px;
                        justify-content: start;
                        flex-direction: column;
                        align-items: center;

                        .title {
                            display: flex;
                            background-color: $dark_light;
                            border-radius: 6px;
                            font-family: PLAY-BOLD;
                            margin: 20px 20px 10px 20px;
                            font-family: PLAY-BOLD;
                            justify-content: center;
                            font-size: 16px;
                            width: 70%;
                            height: 25px;
                            color: $light;
                            align-items: center;
                        }

                        .text {
                            justify-content: center;
                            font-size: 16px;
                            color: $light;
                            font-family: PLAY-REGULAR;
                            padding-top: 15px;
                        }

                        ul {
                            font-family: PLAY-REGULAR;
                            height: 90%;
                            padding-top: 15px;
                            color: $light;
                            font-size: 16px;
                            width: 70%;

                            li {
                                display: flex;
                                padding: 5px;
                                justify-content: space-between;

                                .number {
                                    display: flex;
                                    justify-content: flex-start;
                                    width: 20px;
                                }

                                .player {
                                    display: flex;
                                }

                                .counter {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 30px;
                                }
                            }
                        }

                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;

            .block {
                display: flex;
                height: 220px;
                width: 33%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 20px 10px 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                    text-align: center;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }

                    .img {
                        display: flex;
                        width: 60%;
                        height: 150px;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100px;
                            padding-left: 20px;

                        }
                    }
                }
            }
        }

        .best_players {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            flex-direction: column;

            .block_row {
                display: flex;
                height: 220px;
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;

                .block {
                    display: flex;
                    height: 220px;
                    width: 25%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 18px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 65px;
                        font-family: PLAY-BOLD;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .text {
                        display: flex;
                        color: $light;
                        font-size: 22px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

            .block_row2 {
                display: flex;
                height: 220px;
                width: 100%;
                justify-content: center;
                margin-bottom: 10px;

                .block {
                    display: flex;
                    height: 220px;
                    width: 33%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    background-size: cover;
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 18px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 65px;
                        font-family: PLAY-BOLD;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .text {
                        display: flex;
                        color: $light;
                        font-size: 22px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

        }

        .all_goals {
            display: flex;
            height: 100%;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 1050px;
                width: 48%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 20px 10px 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    font-size: 16px;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    justify-content: center;
                    font-size: 16px;
                    color: $light;
                    font-family: PLAY-REGULAR;
                    padding-top: 15px;
                }

                ul {
                    font-family: PLAY-REGULAR;
                    height: 90%;
                    padding-top: 15px;
                    color: $light;
                    font-size: 16px;
                    width: 90%;

                    li {
                        display: flex;
                        padding: 5px;
                        justify-content: space-between;

                        .number {
                            display: flex;
                            justify-content: flex-start;
                            width: 20px;
                        }

                        .player {
                            display: flex;
                        }

                        .counter {
                            display: flex;
                            justify-content: flex-end;
                            width: 30px;
                        }
                    }
                }

            }

        }
    }
}

@media (max-width: 768px) {

    .wrapper_archive_statistics {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 80px 0 30px 0;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        .title {
            display: flex;
            font-size: 25px;
            color: $light;
            margin-bottom: 20px;
            font-family: PLAY-BOLD;
            justify-content: center;
        }

        .blocks_matches {
            display: flex;
            height: 100%;
            margin-bottom: 0px;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 220px;
                width: 47%;
                margin: 0px 5px 5px 5px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_block_matches {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_wins {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_draw {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_block_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 10px 20px 10px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 80%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .value {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $light;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value2 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $green;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value3 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $gray;
                    font-size: 50px;
                    padding-top: 10px;
                }

                .value4 {
                    display: flex;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    justify-content: center;
                    align-items: center;
                    color: $pink;
                    font-size: 50px;
                    padding-top: 10px;
                }
            }

            .block:first-child {
                margin: 0px 5px 5px 5px;
            }

            .big_block {
                display: flex;
                height: 220px;
                width: 96%;
                margin: 0px 10px 0px 5px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title_block_big_block {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    display: flex;
                    height: 16px;
                    color: $light;
                    justify-content: center;
                    font-family: PLAY-REGULAR;
                    font-size: 14px;
                }
            }
        }

        .container {
            display: flex;
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 0px;
            background-color: $gray_black;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .slider {
                width: 100%;
                height: 100%;
                padding-right: 10px;
                padding-top: 10px;
                display: none;

                .container_slider_css {
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    position: relative;
                    border-radius: 6px;
                }

                .photo_slider_css {
                    position: absolute;
                    animation: round 16s infinite;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    min-height: 0px;
                }

                @keyframes round {
                    25% {
                        opacity: 1
                    }

                    40% {
                        opacity: 0
                    }
                }

                img:nth-child(1) {
                    animation-delay: 12s;
                }

                img:nth-child(2) {
                    animation-delay: 8s;
                }

                img:nth-child(3) {
                    animation-delay: 4s;
                }

                img:nth-child(4) {
                    animation-delay: 0;
                }

                @media(min-width:0px) and (max-width:320px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }

                @media(min-width:321px) and (max-width:480px) {
                    .container_slider_css {
                        width: 80%;
                        height: 190px
                    }
                }
            }

            .blocks {
                display: flex;
                width: 100%;
                flex-direction: column;
                height: 100%;

                .blocks_row:first-child {
                    padding-bottom: 5px;
                }

                .blocks_row {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    flex-wrap: wrap;

                    .block {
                        display: flex;
                        height: 170px;
                        width: 100%;
                        margin: 10px 10px 5px 0px;
                        background-color: rgba(0, 0, 0, 0.4);
                        border-radius: 6px;
                        justify-content: start;
                        flex-direction: column;
                        align-items: center;

                        .title {
                            display: flex;
                            background-color: $dark_light;
                            border-radius: 6px;
                            font-family: PLAY-BOLD;
                            margin: 20px 20px 10px 20px;
                            font-family: PLAY-BOLD;
                            justify-content: center;
                            font-size: 16px;
                            width: 70%;
                            height: 25px;
                            color: $light;
                            align-items: center;
                        }

                        .text {
                            justify-content: center;
                            font-size: 16px;
                            color: $light;
                            font-family: PLAY-REGULAR;
                            padding-top: 15px;
                        }

                        ul {
                            font-family: PLAY-REGULAR;
                            height: 90%;
                            padding-top: 15px;
                            color: $light;
                            font-size: 14px;
                            width: 90%;

                            li {
                                display: flex;
                                padding: 5px;
                                justify-content: space-between;

                                .number {
                                    display: flex;
                                    justify-content: flex-start;
                                    width: 20px;
                                }

                                .player {
                                    display: flex;
                                }

                                .counter {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 30px;
                                }
                            }
                        }

                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            margin-bottom: 10px;
            width: 96%;
            justify-content: center;
            flex-direction: column;

            .block {
                display: flex;
                height: 220px;
                width: 100%;
                margin: 10px 10px 5px 10px;
                padding-right: 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 20px 10px 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                    text-align: center;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 70%;
                    height: 45px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 40px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }

                    .img {
                        display: flex;
                        width: 60%;
                        height: 150px;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100px;
                            padding-left: 20px;

                        }
                    }
                }
            }
        }

        .best_players {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            flex-direction: column;

            .block_row {
                display: flex;
                height: 100%;
                width: 98%;
                margin: 0px 10px 5px 5px;
                justify-content: center;
                margin-bottom: 0px;
                flex-direction: column;

                .block {
                    display: flex;
                    height: 220px;
                    width: 96%;
                    margin: 10px 10px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 18px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 65px;
                        font-family: PLAY-BOLD;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .text {
                        display: flex;
                        color: $light;
                        font-size: 22px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

            .block_row2 {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: center;
                margin-bottom: 0px;
                flex-direction: column;

                .block {
                    display: flex;
                    height: 220px;
                    width: 96%;
                    margin: 10px 10px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;
                    background: linear-gradient(45deg, $dark_light, $gray_black);
                    background-size: cover;
                    transition: 1s;

                    .title {
                        display: flex;
                        color: $gold;
                        font-size: 18px;
                        text-align: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 65px;
                        font-family: PLAY-BOLD;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .text {
                        display: flex;
                        color: $light;
                        font-size: 22px;
                        text-align: center;
                        justify-content: center;
                        width: 100%;
                        height: 40px;
                        padding-top: 40px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block:hover {
                    transform: scale(1.05);
                }
            }

        }

        .all_goals {
            display: flex;
            height: 100%;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 100%;
                width: 98%;
                margin: 0px 10px 5px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;

                .title {
                    display: flex;
                    background-color: $dark_light;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px 20px 10px 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    font-size: 16px;
                    width: 70%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                }

                .text {
                    justify-content: center;
                    font-size: 16px;
                    color: $light;
                    font-family: PLAY-REGULAR;
                    padding-top: 15px;
                    padding-bottom: 10px;
                }

                ul {
                    font-family: PLAY-REGULAR;
                    height: 90%;
                    padding-top: 15px;
                    color: $light;
                    font-size: 16px;
                    width: 90%;

                    li {
                        display: flex;
                        padding: 5px;
                        justify-content: space-between;

                        .number {
                            display: flex;
                            justify-content: flex-start;
                            width: 20px;
                        }

                        .player {
                            display: flex;
                        }

                        .counter {
                            display: flex;
                            justify-content: flex-end;
                            width: 30px;
                        }
                    }
                }

            }

        }
    }
}