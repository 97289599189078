.prosmotr {
    background: $black;
    font-family: PLAY-REGULAR;
    display: flex;
    flex-direction: column;
    width: 100%;

    .about_team {
        height: 100%;
        display: flex;
        margin-top: 190px;
        width: 100%;
        justify-content: center;
        align-content: flex-start;

        .block {
            display: flex;
            flex-direction: column;
            width: 33%;
            height: 600px;
            margin: 20px 20px 20px 20px;
            border-radius: 6px;
            background: $dark_medium;


            .title {
                display: flex;
                font-family: PLAY-Bold;
                font-size: 45px;
                align-items: start;
                height: 50%;
                padding-left: 15px;
                padding-right: 15px;
                justify-content: left;
                text-align: left;
                color: $light;
                padding-top: 150px;

            }

            .text {
                display: flex;
                height: 50%;
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;
                color: $light;
                font-size: 18px;

            }
        }

        .block:nth-child(1) {
            background: $dark;
            transform: translateX(-150%);
            animation: ani 1s forwards;

            @keyframes ani {
                0% {
                    transform: translateX(-150%);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }

        .block:nth-child(2) {
            transform: translateX(-150%);
            animation: ani 1s forwards;

            @keyframes ani {
                0% {
                    transform: translateX(-150%);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }

        .block:nth-child(3) {
            background: $dark_light;
            transform: translateX(-150%);
            animation: ani 1s forwards;

            transform: translateX(-150%);
            animation: ani 1s forwards;

            @keyframes ani {
                0% {
                    transform: translateX(-150%);
                }

                100% {
                    transform: translateY(0);
                }
            }
        }
    }


    .scrolling-wrapper {
        overflow-x: auto;
        /* используем auto вместо scroll */
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        /* Добавляем фиксированную ширину */
        -ms-overflow-style: none;
        /* убираем стандартный скролл для IE */
        scrollbar-width: none;
        /* убираем стандартный скролл для Firefox */
        scroll-behavior: smooth;
        padding-left: 10px;
    }

    .scrolling-wrapper .card {
        display: inline-block;
        padding: 10px 10px;
        overflow-x: hidden;
        /* Добавляем скрытие по горизонтали для изображений */
    }

    .scrolling-wrapper .card img {
        height: auto;
        /* Установите высоту изображений в автоматический режим */
        max-height: 65vh;
        /* Установите максимальную высоту для изображений */
        cursor: grab;
        overflow-x: hidden;
        border-radius: 6px;
    }

    /* меняем курсор на grabbing при перетаскивании изображения */
    .scrolling-wrapper .card img:active {
        cursor: grabbing;
        overflow-x: hidden;
    }

    .scrolling-wrapper::-webkit-scrollbar {
        overflow-x: hidden;
    }

    .coach_prosmotr {
        display: flex;
        flex-direction: column;

        .title {
            text-align: center;
            font-family: PLAY-Bold;
            font-size: 40px;
            color: $light;
            padding-bottom: 20px;
            padding-top: 15px;
        }

        .wrapper_blocks {
            display: flex;
            height: 600px;

            .block_coach_prosmotr {
                display: flex;
                flex-direction: column;
                width: 50%;
                height: 550px;
                margin: 20px 20px 20px 20px;
                border-radius: 6px;
                background: $dark_medium;
                justify-content: center;
                align-items: center;

                .photoblock {
                    display: flex;
                    width: 50%;
                    float: left;
                    position: relative;
                    min-height: 1px;
                    padding-right: 15px;
                    padding-left: 15px;
                    box-sizing: border-box;

                    .card_photo {
                        border-radius: 6px;
                        width: 500px;
                        height: 500px;
                        padding: 20px 0px 0px 0px;
                        margin: 0 auto;
                        text-align: center;
                        box-sizing: border-box;
                        display: block;

                        img {
                            width: 300px;
                            height: 300px;
                            border-radius: 50%;
                            vertical-align: middle;
                            border: 0;
                            overflow-clip-margin: content-box;
                            overflow: clip;
                            text-align: center;
                        }

                        .text_box {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding: 20px 10px 10px 10px;

                            h4 {
                                color: $light;
                                font-size: 25px;
                                font-family: PLAY-REGULAR;
                                margin-bottom: 10px;
                            }

                            h5 {
                                color: $light;
                                font-size: 20px;
                                font-family: PLAY-BOLD;
                                margin-bottom: 10px;
                            }

                            p {
                                color: $gold;
                                font-size: 20px;
                                font-family: PLAY-REGULAR;
                                margin-bottom: 10px;
                            }

                            button {
                                display: inline-block;
                                text-decoration: none;
                                background-color: #ffffff;
                                color: #000000;
                                border: 1px solid #000000;
                                border-radius: 5px;
                                font-size: 24px;
                                padding: 10px 30px;
                                transition: all 0.4s ease;
                                font-family: PLAY-REGULAR;
                                margin-top: 10px;
                            }

                            button:hover {
                                text-decoration: none;
                                background-color: #1c3a75;
                                color: #ffffff;
                                border-color: #000000;
                                font-family: PLAY-REGULAR;
                            }

                        }
                    }
                }
            }

            .block_coach_prosmotr:nth-child(1) {
                background: $dark;
            }
        }
    }

    .schedule {
        display: flex;
        flex-direction: column;

        .schedule_title {
            text-align: center;
            font-family: PLAY-Bold;
            font-size: 3svh;
            color: $light;
            padding-bottom: 10px;
            padding-top: 15px;

        }

        .week_wrapper {
            display: flex;
            padding-left: 10px;
            padding-right: 10px;
            justify-content: center;
            padding-bottom: 15px;
            justify-content: space-around;
            flex-wrap: wrap;

            .schedule_block {
                display: flex;
                flex-direction: column;
                width: 25svh;
                height: 25svh;
                margin: 20px 10px 5px 10px;
                border-radius: 6px;
                background: $blue_light;
                justify-content: center;
                align-items: center;
                color: $light;

                h1 {
                    font-size: 2.3svh;
                    text-align: center;
                    font-family: PLAY-Bold;
                    padding: 10px;
                }

                h2 {
                    font-size: 1.8svh;
                    text-align: center;
                    font-family: PLAY-Regular;
                    padding: 10px;
                }

                h3 {
                    font-size: 1.8svh;
                    text-align: center;
                    font-family: PLAY-Bold;
                    padding: 10px;
                }
            }

            .schedule_block:nth-child(1) {
                background-color: $dark_light;
            }

            .schedule_block:nth-child(2) {
                background-color: $dark;
            }

            .schedule_block:nth-child(3) {
                background-color: $dark;
            }

            .schedule_block:nth-child(4) {
                background-color: $blue_light;
            }

            .schedule_block:nth-child(5) {
                background-color: $dark_light;
            }

            .schedule_block:nth-child(6) {
                background-color: $blue_medium;
            }

            .schedule_block:nth-child(7) {
                background-color: $blue_medium;
            }
        }
    }


    .view_in_team {
        width: 100%;
        height: 650px;
        display: flex;
        z-index: 0;


        .team_88 {
            width: 50%;
            height: 100%;
            position: relative;
            transition: .3s;

            &:hover {
                .fill {
                    opacity: 0;
                }

                .background {
                    filter: grayscale(0);
                }

                .text {
                    text-shadow: $black 1px 0 10px;
                }
            }

            .fill {
                position: absolute;
                top: 0;
                left: 0;
                background: $gray_black;
                opacity: .8;
                width: 100%;
                height: 100%;
                z-index: 2;
                transition: .3s;
            }

            .background {
                background-image: url(../img/team88.png);
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                filter: grayscale(1);
                transition: .3s;
            }

            .content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 95%;
                color: white;
                font-family: PLAY-BOLD;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 3;

                .text {
                    display: flex;
                    justify-content: center;
                    font-size: 135px;
                }

                .view {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                }

                @import "elements/_buttons.scss";
            }
        }

        .team_pro {
            width: 50%;
            height: 100;
            position: relative;

            &:hover {
                .fill {
                    opacity: 0;
                }

                .background {
                    filter: grayscale(0);
                }

                .text {
                    text-shadow: $black 1px 0 10px;
                }
            }

            .fill {
                position: absolute;
                top: 0;
                left: 0;
                background: $dark;
                opacity: .8;
                width: 100%;
                height: 100%;
                z-index: 2;
                transition: .3s;
            }

            .background {
                background-image: url(../img/teampro.jpg);
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                filter: grayscale(1);
                transition: .3s;
            }

            .content {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 95%;
                color: white;
                font-family: PLAY-BOLD;
                display: flex;
                flex-direction: column;
                justify-content: center;
                z-index: 3;

                .text {
                    display: flex;
                    justify-content: center;
                    font-size: 135px;
                }

                .view {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                }

                @import "elements/_buttons.scss";
            }
        }
    }
}

@import "media/_prosmotr.scss";