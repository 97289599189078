$light: white;
$dark: #00296B;
$dark_medium: #003F88;
$dark_light: #00509D;
$dark_light_50: rgba(0, 80, 157, .8);
$black: black;
$gray: gray;
$gray_50: rgba(128, 128, 128, .1);
$gray_black: #282933;
$gold: #FDC500;
$green: #c6dc78;
$pink: #f98987;
$gray_light: #c5c2c2;
$gray_light_50: rgb(198, 195, 195, .4);
$blue_medium: #2D62B5;
$blue_light: #5178B5;