@media (max-width: 768px) {
    .contacts {
        padding: 100px 20px 60px 20px;

        .contacts_wrapper {
            flex-direction: column;
            width: 100%;

            .logo {
                margin-right: 0px;
                width: 200px;
                margin: 0 auto;
                padding-bottom: 30px;

                img {
                    display: flex;
                    width: 100%;
                }
            }

            .contact {
                display: flex;
                max-width: 400px;
                width: 100%;
                justify-content: center;
                flex-direction: column;
                margin: 0 auto;

                .contact_form {
                    padding-left: 0px;
                }
            }
        }
    }
}