* {
    margin: 0;
}

.wrapper_prosmotrpro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: white;
    padding-top: 190px;
    font-family: play-regular;

    .about_team {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: 20px 0px 0 20px;

        .text_block {
            height: 100%;
            width: 70%;
            color: $black;
            margin: 40px 60px 40px 60px;

            .title {
                font-size: 40px;
                font-family: play-bold;
                margin-bottom: 15px;
            }

            .title_2 {
                font-size: 35px;
                font-family: play-bold;
                margin-bottom: 40px;
            }

            .text {
                font-size: 24px;
                padding-top: 15px;
            }

            .text-2 {
                font-size: 24px;
                font-family: play-bold;
                padding-top: 20px;
            }

            .icon_block {
                height: 120px;
                display: flex;
                justify-content: left;
                width: 20%;
                padding-top: 50px;

                img {
                    height: 100%;
                    width: 100%;
                    padding-right: 20px;
                }
            }
        }

        .icon_tactics {
            width: 250px;
            align-items: center;
            justify-content: center;
            padding-bottom: 40px;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .advantages {
        display: flex;
        width: 100%;
        padding: 20px 60px 20px 60px;
        justify-content: center;

        .block {
            display: flex;
            flex-direction: column;
            height: 250px;
            width: 25%;
            border-radius: 6px;
            margin-left: 20px;
            margin-right: 20px;
            -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);


            .icon {
                height: 75%;
                width: 100%;
                padding: 20px;
                display: flex;
                justify-content: center;
            }

            .text {
                text-align: center;
                height: 25%;
                padding: 20px;
                font-size: 16px;
            }
        }
    }

    .coach_prosmotr {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 80px 10px 80px;

        .title {
            text-align: center;
            font-family: PLAY-Bold;
            font-size: 40px;
            color: $black;
            padding-bottom: 20px;
            padding-top: 15px;
        }

        .wrapper_blocks {
            display: flex;
            height: 600px;


            .block_coach_prosmotr {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 550px;
                margin: 20px 10px 20px 10px;
                border-radius: 6px;
                color: $black;
                justify-content: center;
                align-items: center;
                -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);

                .photoblock {
                    display: flex;
                    width: 100%;
                    float: left;
                    position: relative;
                    min-height: 1px;
                    padding-right: 15px;
                    padding-left: 15px;
                    box-sizing: border-box;

                    .card_photo {
                        border-radius: 6px;
                        width: 100%;
                        height: 500px;
                        padding: 20px 0px 0px 0px;
                        margin: 0 auto;
                        text-align: center;
                        box-sizing: border-box;
                        display: block;

                        img {
                            width: 300px;
                            height: 300px;
                            border-radius: 50%;
                            vertical-align: middle;
                            border: 0;
                            overflow-clip-margin: content-box;
                            overflow: clip;
                            text-align: center;
                            -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                            -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                            box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                        }

                        .text_box {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            padding: 20px 10px 10px 10px;

                            h4 {
                                color: $black;
                                font-size: 25px;
                                font-family: PLAY-REGULAR;
                                margin-bottom: 10px;
                            }

                            h5 {
                                color: $black;
                                font-size: 20px;
                                font-family: PLAY-BOLD;
                                margin-bottom: 10px;
                            }

                            p {
                                color: $dark;
                                font-size: 20px;
                                font-family: PLAY-bold;
                                margin-bottom: 10px;
                            }

                            button {
                                display: inline-block;
                                text-decoration: none;
                                background-color: #1c3a75;
                                color: #ffffff;
                                border: 1px solid #000000;
                                border-radius: 5px;
                                font-size: 24px;
                                padding: 10px 30px;
                                transition: all 0.4s ease;
                                font-family: PLAY-REGULAR;
                                margin-top: 10px;
                            }

                            button:hover {
                                text-decoration: none;
                                background-color: #ffffff;
                                color: #000000;
                                border-color: #000000;
                                font-family: PLAY-REGULAR;
                            }

                        }
                    }
                }
            }
        }
    }

    .training_place {
        display: flex;
        min-height: 500px;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        justify-content: center;

        .block_place {
            display: flex;
            margin: 10px 80px 10px 80px;
            justify-content: center;
            align-items: center;
            background-color: $light;
            border-radius: 6px;
            -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);

            .photo {
                height: 100%;
                width: 40%;
                padding: 20px;
                justify-content: center;
                align-items: center;
                display: flex;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 6px;
                    -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                    -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                    box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                }
            }

            .info_block {
                display: flex;
                height: 100%;
                width: 60%;
                padding: 20px;
                align-items: center;
                align-items: center;
                justify-content: center;

                .text_box {
                    flex-direction: column;
                    align-items: center;
                    width: 100%;

                    .title {
                        display: flex;
                        text-align: left;
                        font-size: 40px;
                        font-family: play-bold;
                        color: $black;
                    }

                    .text {
                        display: flex;
                        padding-top: 30px;
                        text-align: left;
                        font-size: 18px;
                        font-family: play-regular;
                        color: $black;
                    }
                }
            }
        }
    }

    .prices {
        display: flex;
        width: 100%;
        padding: 20px 60px 20px 60px;
        justify-content: center;
        background-color: $light;


        .block11x11 {
            display: flex;
            flex-direction: column;
            height: 500px;
            width: 33%;
            border-radius: 6px;
            margin-left: 20px;
            margin-right: 20px;
            -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            background-color: $light;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px;
                background: $black;
                color: $light;
                padding: 5px;
                font-size: 14px;
                height: 6%;
                border-radius: 6px;
            }

            .value {
                display: flex;
                justify-content: center;
                font-size: 50px;
                color: $dark-light;
                font-family: play-bold;
                height: 10%;
            }

            .text {
                padding-top: 10px;
                display: flex;
                justify-content: center;
                padding: 15px;
                text-align: center;
                height: 10%;
            }

            .list {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: left;
                padding: 15px;
                height: 50%;

                li {
                    list-style: decimal url('../img/icon/check.png') outside;
                    padding-bottom: 10px;
                }
            }

            .button a {
                display: flex;
                height: 15%;
                width: 100%;
                padding: 15px;
                display: flex;
                flex-direction: column;

                button {
                    display: inline-block;
                    text-decoration: none;
                    background-color: #1c3a75;
                    color: #ffffff;
                    border: 1px solid #000000;
                    border-radius: 5px;
                    font-size: 24px;
                    padding: 10px 10px;
                    transition: all 0.4s ease;
                    font-family: PLAY-REGULAR;
                }

                button:hover {
                    text-decoration: none;
                    background-color: #ffffff;
                    color: #000000;
                    border-color: #000000;
                    font-family: PLAY-REGULAR;
                }
            }
        }
    }

    .questionnaire {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-family: PLAY-REGULAR;
        margin-right: 80px;
        margin-left: 80px;
        -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
        box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
        background-color: $light;
        border-radius: 6px;
        margin-bottom: 10px;

        .title {
            justify-content: center;
            font-size: 40px;
            padding-top: 20px;
            text-align: center;
            font-family: PLAY-Bold;
            padding-bottom: 10px;
        }

        form {
            display: flex;
            flex-direction: column;
            text-align: left;
            font-family: PLAY-REGULAR;
            width: 100%;
            margin-left: 20px;
            padding-right: 30px;
            padding-bottom: 20px;

            .field.error .item {
                border-color: $pink;
            }

            .field .error-txt {
                font-size: 12px;
                color: $pink;
                text-align: left;
                margin: -5px 0 10px;
                display: none;
            }

            .field.error .error-txt {
                display: block;
            }

            .form .textarea-field .error-txt {
                margin-top: -10px;
            }

            input,
            textarea {
                border-radius: 0;
                font-family: PLAY-REGULAR;
                font-size: 15px;
                border: 0;
                border-bottom: 1px solid $gray;
                width: 100%;
                margin-bottom: 30px;
                padding: 10px 0;
                background: none;
                color: $dark;
            }

            textarea {
                height: 150px;
                resize: none;
            }

            input:focus,
            textarea:focus {
                outline: none;
                border-bottom: 2px solid $dark;
            }

            .input-field,
            .textarea-field {
                position: relative;
            }

            label {
                position: absolute;
                left: 10px;
                top: 10px;
                font-size: 15px;
                color: $dark;
                pointer-events: none;
                transition: 0.2s ease all;
            }

            input:focus+label,
            textarea:focus+label,
            input:not(:placeholder-shown)+label,
            textarea:not(:placeholder-shown)+label {
                top: -20px;
                left: 10px;
                font-size: 12px;
                color: $dark;
            }

            button {
                display: inline-block;
                text-decoration: none;
                background-color: #1c3a75;
                color: #ffffff;
                border: 1px solid #000000;
                border-radius: 5px;
                font-size: 24px;
                padding: 10px 30px;
                transition: all 0.4s ease;
                font-family: PLAY-REGULAR;
                margin-bottom: 10px;
                margin-top: 15px;
            }

            button:hover {
                text-decoration: none;
                background-color: #ffffff;
                color: #000000;
                border-color: #000000;
                font-family: PLAY-REGULAR;
            }

            .text {
                font-size: 15px;
                text-align: start;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }

    .faq {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        width: 100%;
        margin-top: 10px;
        background-color: $dark_medium;
        color: $light;
        padding-bottom: 10px;


        .title {
            padding-left: 4%;
            padding-top: 10px;
            font-size: 3svh;
            padding-bottom: 15px;
            text-align: left;
            font-family: play-bold;

        }
    }

    .faq summary {
        padding-left: 4%;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: $dark_medium;
        color: $light;
        font-size: 2svh;
    }

    .faq p {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5%;
        background-color: $blue_light;
        color: $light;
    }
}

@import "media/_prosmotr_pro11x11.scss";