.container {
    width: 100%;
    height: 1000px;
    background: $black;
    overflow: hidden;

    .swich {
        input {
            display: none;

        }

        .controls {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            gap: 10px;

            label {
                width: 20px;
                height: 20px;
                background: $light;
                border-radius: 20px;
                cursor: pointer;
                opacity: 0.4;
            }
        }

        input:nth-child(1) :checked~.controls label:nth-child(1) {
            opacity: 1;
            width: 50px;
        }
    }
}