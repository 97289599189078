.gallery {
    background-color: $black;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    font-family: PLAY-REGULAR;
    background: $black;

    .content {
        display: flex;
        height: 100%;
        width: 100%;
        margin-top: 190px;
        justify-content: center;


        @import url('https://fonts.googleapis.com/css?family=Just+Another+Hand');

        $big_width: 70%; //Slider width - large display
        $big_height: 45%; //Slider height - large display
        $big_nav_width: 12%; // Previous and Next width - large display

        $small_width: 100%; //Slider width - small display
        $small_height: 65%; //Slider height - small display
        $small_nav_width: 15%; // Previous and Next width - small display

        $box-shadow: 0 11px 22px rgba(0, 0, 0, 0.20), 0 7px 7px rgba(0, 0, 0, 0.24);
        $brown: $gray;
        $blue: $light;
        $green: $light;
        $magenta: $light;
        $nav_hover_color: $light;

        .container {
            margin: 0 auto;
            margin-top: 20px;
            margin-bottom: 20px;
            position: relative;
            width: $big_width;
            height: 0;
            padding-bottom: $big_height;
            user-select: none;
            background-color: #1c1c1c;
            box-shadow: $box-shadow;

            input {
                display: none;
            }

            .slide_img {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;

                img {
                    width: inherit;
                    height: inherit;
                }

                .prev,
                .next {
                    width: $big_nav_width;
                    height: inherit;
                    position: absolute;
                    top: 0;
                    z-index: 99;
                    transition: .45s;
                    cursor: pointer;
                    text-align: center;
                }

                .next {
                    right: 0;
                }

                .prev {
                    left: 0;
                }

                .prev:hover,
                .next:hover {
                    transition: .3s;
                    background-color: $nav_hover_color;
                    opacity: .3;
                }

                .prev span,
                .next span {
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border: solid 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .prev span {
                    border-color: transparent #fff transparent transparent;
                    right: 35%;
                }

                .next span {
                    border-color: transparent transparent transparent #fff;
                    left: 35%;
                }
            }

            #nav_slide {
                width: 100%;
                bottom: 12%;
                height: 11px;
                position: absolute;
                text-align: center;
                z-index: 99;
                cursor: default;

                .dots {
                    top: -5px;
                    width: 18px;
                    height: 18px;
                    margin: 0 3px;
                    position: relative;
                    border-radius: 100%;
                    display: inline-block;
                    background-color: rgba(0, 0, 0, 0.6);
                    transition: .4s;
                    cursor: pointer;
                }

                #dot1:hover {
                    background: $brown;
                }

                #dot2:hover {
                    background: $magenta;
                }

                #dot3:hover {
                    background: $blue;
                }

                #dot4:hover {
                    background: $green;
                }
            }
        }

        #i1:checked~#one,
        #i2:checked~#two,
        #i3:checked~#three,
        #i4:checked~#four {
            z-index: 9;
            animation: scroll 1s ease-in-out;
        }

        #i1:checked~#nav_slide #dot1 {
            background: $brown;
        }

        #i2:checked~#nav_slide #dot2 {
            background: $magenta;
        }

        #i3:checked~#nav_slide #dot3 {
            background: $blue;
        }

        #i4:checked~#nav_slide #dot4 {
            background: $green;
        }



        @keyframes scroll {
            0% {
                opacity: .4;
            }

            100% {
                opacity: 1;
            }
        }

        @media screen and (max-width: 685px) {
            .container {
                border: none;
                width: $small_width;
                height: 0;
                padding-bottom: $small_height;

                .slide_img {

                    .prev,
                    .next {
                        width: $small_nav_width;
                    }

                    .prev span,
                    .next span {
                        border: solid 12px;
                    }

                    .prev span {
                        border-color: transparent #fff transparent transparent;
                    }

                    .next span {
                        border-color: transparent transparent transparent #fff;
                    }
                }

                #nav_slide .dots {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

@import "media/_gallery.scss";