@media (max-width: 1366px) {
    .personal_data {
        display: flex;
        width: 100%;
        background-color: $light;
        justify-content: center;
        padding: 210px 50px 60px 50px;
        flex-direction: column;
        justify-content: center;
    }
}

@media (max-width: 1024px) {
    .personal_data {
        display: flex;
        width: 100%;
        background-color: $light;
        justify-content: center;
        padding: 210px 20px 60px 20px;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 32px;
        }
    }
}

@media (max-width: 768px) {
    .personal_data {
        display: flex;
        width: 100%;
        background-color: $light;
        justify-content: center;
        padding: 110px 10px 60px 10px;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 32px;
            text-align: center;
        }

        .text {
            text-align: center;
        }
    }
}