@media (max-width: 1366px) {
    .prosmotr {
        .about_team {
            margin-top: 170px;

            .block {
                height: 700px;
                margin: 10px;
                width: 30%;

                .title {
                    font-size: 30px;
                    height: 20%;
                    padding-top: 150px;
                }

                .text {
                    height: 80%;
                }
            }
        }

        .coach_prosmotr {

            .title {}

            .wrapper_blocks {

                .block_coach_prosmotr {

                    .photoblock {
                        display: flex;
                        width: 100%;
                        position: relative;
                        min-height: 1px;
                        box-sizing: border-box;

                        .card_photo {
                            border-radius: 6px;
                            width: 400px;
                            height: 500px;
                            padding: 20px 0px 0px 0px;
                            margin: 0 auto;
                            text-align: center;
                            box-sizing: border-box;
                            display: block;

                            img {
                                width: 250px;
                                height: 250px;
                            }

                            .text_box {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 40px 10px 10px 10px;
                            }
                        }
                    }
                }
            }
        }

        .schedule {
            .schedule_title {}

            .week_wrapper {
                .group_1 {
                    .schedule_block {
                        width: 20%;
                    }
                }

                .group_2 {
                    .schedule_block {
                        width: 20%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {

    .prosmotr {
        .about_team {
            margin-top: 160px;

            .block {
                height: 750px;
                margin: 10px;
                width: 30%;

                .title {
                    height: 10%;
                    padding-top: 20px;
                    font-size: 23px;
                }

                .text {
                    align-items: center;
                    padding-top: 80px;
                    height: 85%;
                    padding-top: 25px;
                }
            }
        }

        .photo {
            .block {
                .img-container {
                    gap: 3px;

                    img {
                        height: 400px;
                        margin: 5px;
                    }
                }

                .pop-up {
                    img {
                        width: 80%;
                    }
                }
            }
        }

        .coach_prosmotr {
            display: flex;
            flex-direction: column;

            .title {
                text-align: center;
                font-family: PLAY-Bold;
                font-size: 40px;
                color: $light;
                padding-bottom: 20px;
                padding-top: 20px;
            }

            .wrapper_blocks {
                display: flex;
                height: 600px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 450px;
                padding-left: 20px;
                padding-right: 20px;

                .block_coach_prosmotr {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 550px;
                    padding: 0;
                    border-radius: 6px;
                    background: $dark_medium;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 20px;

                    .photoblock {
                        display: flex;
                        width: 100%;
                        position: relative;
                        min-height: 1px;
                        box-sizing: border-box;
                        padding: 0px;


                        .card_photo {
                            border-radius: 6px;
                            width: 100%;
                            height: 500px;
                            padding: 20px 20px 0px 20px;
                            margin: 0 auto;
                            text-align: center;
                            box-sizing: border-box;
                            display: block;

                            img {
                                width: 250px;
                                height: 250px;
                                border-radius: 50%;
                                vertical-align: middle;
                                border: 0;
                                overflow-clip-margin: content-box;
                                overflow: clip;
                                text-align: center;
                            }

                            .text_box {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 40px 10px 10px 10px;

                                h4 {
                                    color: $light;
                                    font-size: 25px;
                                    font-family: PLAY-REGULAR;
                                    margin-bottom: 10px;
                                }

                                h5 {
                                    color: $light;
                                    font-size: 20px;
                                    font-family: PLAY-BOLD;
                                    margin-bottom: 10px;
                                }

                                p {
                                    color: $gold;
                                    font-size: 20px;
                                    font-family: PLAY-REGULAR;
                                    margin-bottom: 10px;
                                }

                                button {
                                    display: inline-block;
                                    text-decoration: none;
                                    background-color: #ffffff;
                                    color: #000000;
                                    border: 1px solid #000000;
                                    border-radius: 5px;
                                    font-size: 24px;
                                    padding: 10px 30px;
                                    transition: all 0.4s ease;
                                    font-family: PLAY-REGULAR;
                                    margin-top: 10px;
                                }

                                button:hover {
                                    text-decoration: none;
                                    background-color: #1c3a75;
                                    color: #ffffff;
                                    border-color: #000000;
                                    font-family: PLAY-REGULAR;
                                }

                            }
                        }
                    }
                }

                .block_coach_prosmotr:nth-child(1) {
                    background: $dark;
                }
            }
        }

        .schedule {
            margin-top: 430px;

            .schedule_title {}

            .week_wrapper {
                .schedule_block {
                    width: 30%;
                }
            }
        }

        .view_in_team {
            width: 100%;
            height: 650px;
            display: flex;
            z-index: 0;


            .team_88 {
                width: 50%;
                height: 100%;
                position: relative;
                transition: .3s;

                &:hover {
                    .fill {
                        opacity: 0;
                    }

                    .background {
                        filter: grayscale(0);
                    }

                    .text {
                        text-shadow: $black 1px 0 10px;
                    }
                }

                .fill {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $gray_black;
                    opacity: .8;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    transition: .3s;
                }

                .background {
                    background-image: url(../img/team88.png);
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    filter: grayscale(1);
                    transition: .3s;
                }

                .content {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 95%;
                    color: white;
                    font-family: PLAY-BOLD;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    z-index: 3;

                    .text {
                        display: flex;
                        justify-content: center;
                        font-size: 100px;
                    }

                    .view {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 20px;
                    }

                    @import "elements/_buttons.scss";
                }
            }

            .team_pro {
                width: 50%;
                height: 100;
                position: relative;

                &:hover {
                    .fill {
                        opacity: 0;
                    }

                    .background {
                        filter: grayscale(0);
                    }

                    .text {
                        text-shadow: $black 1px 0 10px;
                    }
                }

                .fill {
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $dark;
                    opacity: .8;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    transition: .3s;
                }

                .background {
                    background-image: url(../img/teampro.png);
                    height: 100%;
                    width: 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    filter: grayscale(1);
                    transition: .3s;
                }

                .content {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 95%;
                    color: white;
                    font-family: PLAY-BOLD;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    z-index: 3;

                    .text {
                        display: flex;
                        justify-content: center;
                        font-size: 100px;
                    }

                    .view {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 20px;
                    }

                    @import "elements/_buttons.scss";
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .prosmotr {
        .about_team {
            margin-top: 70px;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;

            .block {
                height: 400px;
                margin: 10px 0px 10px 0px;
                width: 100%;

                .title {
                    height: 15%;
                    padding-top: 20px;
                    font-size: 23px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .text {
                    align-items: center;
                    padding-top: 20px;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .scrolling-wrapper {
            padding-left: 0px;
        }


        .scrolling-wrapper .card img {
            max-height: 55vh;
        }

        .coach_prosmotr {
            display: flex;
            flex-direction: column;

            .title {
                text-align: center;
                font-family: PLAY-Bold;
                font-size: 40px;
                color: $light;
                padding-bottom: 20px;
                padding-top: 20px;
            }

            .wrapper_blocks {
                display: flex;
                height: 600px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 450px;
                padding-left: 5px;
                padding-right: 5px;

                .block_coach_prosmotr {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 550px;
                    padding: 0;
                    border-radius: 6px;
                    background: $dark_medium;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                    margin-bottom: 20px;

                    .photoblock {
                        display: flex;
                        width: 100%;
                        position: relative;
                        min-height: 1px;
                        box-sizing: border-box;
                        padding: 0px;


                        .card_photo {
                            border-radius: 6px;
                            width: 100%;
                            height: 500px;
                            padding: 20px 20px 0px 20px;
                            margin: 0 auto;
                            text-align: center;
                            box-sizing: border-box;
                            display: block;

                            img {
                                width: 250px;
                                height: 250px;
                                border-radius: 50%;
                                vertical-align: middle;
                                border: 0;
                                overflow-clip-margin: content-box;
                                overflow: clip;
                                text-align: center;
                            }

                            .text_box {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                padding: 40px 10px 10px 10px;

                                h4 {
                                    color: $light;
                                    font-size: 25px;
                                    font-family: PLAY-REGULAR;
                                    margin-bottom: 10px;
                                }

                                h5 {
                                    color: $light;
                                    font-size: 20px;
                                    font-family: PLAY-BOLD;
                                    margin-bottom: 10px;
                                }

                                p {
                                    color: $gold;
                                    font-size: 20px;
                                    font-family: PLAY-REGULAR;
                                    margin-bottom: 10px;
                                }

                                button {
                                    display: inline-block;
                                    text-decoration: none;
                                    background-color: #ffffff;
                                    color: #000000;
                                    border: 1px solid #000000;
                                    border-radius: 5px;
                                    font-size: 24px;
                                    padding: 10px 30px;
                                    transition: all 0.4s ease;
                                    font-family: PLAY-REGULAR;
                                    margin-top: 10px;
                                }

                                button:hover {
                                    text-decoration: none;
                                    background-color: #1c3a75;
                                    color: #ffffff;
                                    border-color: #000000;
                                    font-family: PLAY-REGULAR;
                                }

                            }
                        }
                    }
                }

                .block_coach_prosmotr:nth-child(1) {
                    background: $dark;
                }
            }
        }

        .schedule {
            margin-top: 430px;
            justify-content: start;

            .week_wrapper {
                display: flex;
                flex-direction: row;
                /* горизонтальное расположение блоков */
                overflow-x: auto;
                /* горизонтальный скролл */
                -webkit-overflow-scrolling: touch;
                /* плавный скроллинг на мобильных устройствах */
                padding-left: 0;
                padding-right: 0;
                justify-content: start;
                flex-wrap: nowrap;
                scrollbar-width: none;
                /* для Firefox */

                .schedule_block {
                    flex: 0 0 auto;
                    /* предотвращаем сжатие блоков */
                    width: auto;
                    /* для удобства скроллинга убираем фиксированную ширину */
                    min-width: 25svh;
                    /* минимальная ширина блока, чтобы они не становились слишком узкими */
                    margin: 5px;
                    /* добавляем отступы между блоками */
                }

                .week_wrapper::-webkit-scrollbar {
                    display: none;
                    /* для Chrome, Safari и Opera */
                }
            }
        }


        .view_in_team {
            height: auto;
            flex-direction: column;

            .team_88 {
                width: 100%;
                height: 400px;

                .content {
                    .text {
                        font-size: 90px;
                    }
                }
            }

            .team_pro {
                width: 100%;
                height: 400px;

                .content {
                    .text {
                        font-size: 90px;
                    }
                }
            }
        }
    }
}