@media (max-width: 1366px) {

    .page_player {

        .player {
            padding: 230px 0 60px 0;

            .photoblock {

                .card_photo {

                    img {}

                    h3 {}
                }
            }

            .aboutme {

                .name {

                    h3 {}

                    p {
                        width: 75%;
                    }
                }

                .position_number {
                    display: flex;
                    text-align: left;

                    .number {
                        color: $light;
                        font-size: 30px;
                        font-family: PLAY-REGULAR;
                        padding-right: 20px;
                    }

                    .position {
                        color: $gold;
                        font-size: 30px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .information {
                    .information_me {
                        li {


                            .title_information {
                                width: 30%;
                            }

                            .text {

                                a {}
                            }
                        }
                    }
                }
            }
        }

        .statisticplayersall {
            display: flex;
            background-color: $gray_black;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $light;
                font-size: 40px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 50px;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $light;

                    .text {
                        font-size: 16px;
                        color: $light;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .statisticthisyears {
            display: flex;
            background-color: $light;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $black;
                font-size: 40px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 50px;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $black;

                    .text {
                        font-size: 16px;
                        color: $black;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .wrapper_achieved {

            .achieved {

                .experience {
                    display: flex;
                    width: 100%;
                    flex-direction: column;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 20px;
                    }

                    .wrapper_awards {
                        justify-content: center;
                        display: flex;
                        margin-left: 10%;
                        margin-right: 10%;
                        padding-bottom: 60px;

                        .block_awards {
                            width: 20%;
                            height: 100px;
                            gap: 1rem;
                            text-align: center;
                            border-radius: 6px;
                            justify-content: center;
                            align-items: center;
                            font-size: 5svh;
                            font-family: PLAY-BOLD;
                            color: $light;
                            text-align: center;

                            .text {
                                font-family: PLAY-REGULAR;
                                color: $light;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                text-align: center;
                                justify-content: center;
                                padding-left: 20%;
                                padding-right: 20%;
                                font-size: 18px;
                            }
                        }
                    }
                }

                .education {
                    display: flex;
                    width: 100%;
                    padding-left: 100px;
                    padding-right: 5%;

                    .title {
                        padding-top: 20px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 40px;
                    height: 100%;


                    .text {
                        display: flex;
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .year {
                            display: flex;
                            font-family: PLAY-BOLD;
                            color: $light;
                            width: 25%;
                            font-size: 25px;
                            padding-right: 100px;
                        }

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .photo {
            height: 100%;
            display: flex;
            width: 100%;
            justify-content: center;
            align-content: flex-start;
            flex-wrap: wrap;

            .title_gallerey {
                font-size: 40px;
                color: $gray_black;
                font-family: PLAY-BOLD;
                padding-top: 40px;
                justify-content: center;
                text-align: center;
                padding-top: 30px;
            }

            .block {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 0px 0px 20px 0px;
                border-radius: 6px;
                background: $light;
                justify-content: center;
                align-items: center;

                .img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin: 10px;

                    img {
                        will-change: 350px;
                        margin: 20px;
                        height: 250px;
                        border-radius: 5px;
                        overflow: hidden;
                        transition: .5s;
                        z-index: 0;
                    }

                    img:hover {
                        transform: scale(1.1);
                        z-index: 0;
                    }
                }

                .pop-up {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(0, 0, 0, 0.8);
                    display: none;
                    z-index: 1;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 3px;
                        width: 80%;
                        z-index: 1;
                    }

                    span {
                        position: fixed;
                        top: 190px;
                        right: 20px;
                        width: 40px;
                        height: 40px;
                        color: $light;
                        font-size: 50px;
                        font-weight: 900;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .wrapper_internships_courses {
            display: flex;
            font-size: 14px;
            background-color: $gray_black;

            .internships_courses {
                display: flex;
                padding: 20px 20px 0px 20px;
                justify-content: center;
                text-align: left;
                width: 100%;

                .internships {
                    display: flex;
                    width: 50%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 60px;
                        padding-bottom: 20px;
                    }
                }

                .courses {
                    display: flex;
                    width: 50%;
                    flex-direction: column;
                    padding-left: 100px;
                    padding-right: 5%;

                    .title {
                        padding-top: 60px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-bottom: 20px;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 20px;
                    height: 250px;

                    .year {
                        display: flex;
                        font-family: PLAY-BOLD;
                        color: $light;
                        width: 25%;
                        font-size: 25px;
                        padding-right: 20px;
                    }

                    .text {
                        flex-direction: column;
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 1024px) {

    .page_player {

        .player {
            padding: 160px 0 60px 0;
            flex-direction: column;
            justify-content: center;

            .photoblock {
                width: 100%;

                .card_photo {
                    height: 430px;
                    padding: 33px 33px 10px 33px;

                    img {}

                    h3 {}
                }
            }

            .aboutme {
                width: 100%;
                text-align: center;

                .name {

                    h3 {
                        padding-top: 30px;
                    }

                    p {
                        width: 100%;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .position_number {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .number {
                        color: $light;
                        font-size: 30px;
                        font-family: PLAY-REGULAR;
                        padding-right: 20px;
                    }

                    .position {
                        color: $gold;
                        font-size: 30px;
                        font-family: PLAY-REGULAR;
                    }
                }

                .information {
                    .information_me {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        li {
                            display: flex;
                            justify-content: center;

                            .title_information {
                                width: 150px;
                                text-align: left;
                            }

                            .text {
                                width: 150px;
                                text-align: right;

                                a {}
                            }
                        }
                    }
                }
            }
        }

        .statisticplayersall {
            display: flex;
            background-color: $gray_black;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $light;
                font-size: 40px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 40px;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $light;

                    .text {
                        font-size: 12px;
                        color: $light;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .statisticthisyears {
            display: flex;
            background-color: $light;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $black;
                font-size: 40px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 40px;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $black;

                    .text {
                        font-size: 12px;
                        color: $black;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .wrapper_achieved {

            .achieved {
                flex-direction: column;

                .experience {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 0%;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 20px;
                    }

                    .wrapper_awards {
                        justify-content: center;
                        display: flex;
                        margin-left: 5%;
                        margin-right: 5%;
                        padding-bottom: 60px;

                        .block_awards {
                            width: 20%;
                            height: 100px;
                            gap: 1rem;
                            text-align: center;
                            border-radius: 6px;
                            justify-content: center;
                            align-items: center;
                            font-size: 5svh;
                            font-family: PLAY-BOLD;
                            color: $light;
                            text-align: center;

                            .text {
                                font-family: PLAY-REGULAR;
                                color: $light;
                                padding-top: 20px;
                                padding-bottom: 20px;
                                text-align: center;
                                justify-content: center;
                                padding-left: 15%;
                                padding-right: 15%;
                                font-size: 18px;
                            }
                        }
                    }
                }

                .education {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 0%;

                    .title {
                        padding-top: 20px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 40px;
                    height: 100%;


                    .text {
                        flex-direction: flex;
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .year {
                            display: flex;
                            font-family: PLAY-BOLD;
                            color: $light;
                            width: 25%;
                            font-size: 25px;
                            padding-right: 100px;
                        }

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .photo {
            .block {
                .img-container {
                    gap: 3px;

                    img {
                        height: 400px;
                        margin: 5px;
                    }
                }

                .pop-up {
                    img {
                        width: 80%;
                    }
                }
            }
        }

        .wrapper_internships_courses {
            display: flex;
            font-size: 14px;
            background-color: $gray_black;

            .internships_courses {
                display: flex;
                padding: 20px 20px 0px 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                flex-direction: column;

                .internships {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 60px;
                        padding-bottom: 20px;
                    }
                }

                .courses {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        padding-top: 10px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-bottom: 20px;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 20px;
                    height: 200px;

                    .year {
                        display: flex;
                        font-family: PLAY-BOLD;
                        color: $light;
                        width: 25%;
                        font-size: 25px;
                        padding-right: 20px;
                    }

                    .text {
                        flex-direction: column;
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: 768px) {

    .page_player {

        .player {
            padding: 90px 0 60px 0;
            flex-direction: column;
            justify-content: center;

            .photoblock {
                width: 100%;

                .card_photo {
                    width: 300px;
                    height: 250px;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 20px 0px 20px;

                    img {
                        width: 200px;
                        height: 200px;
                    }

                    h3 {}
                }
            }

            .aboutme {
                width: 100%;
                text-align: center;

                .name {

                    h3 {
                        padding-top: 30px;
                    }

                    p {
                        width: 100%;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .information {
                    .information_me {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        li {
                            display: flex;
                            justify-content: center;

                            .title_information {
                                width: 150px;
                                text-align: left;
                            }

                            .text {
                                width: 150px;
                                text-align: right;

                                a {}
                            }
                        }
                    }
                }
            }
        }

        .statisticplayersall {
            display: flex;
            background-color: $gray_black;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $light;
                font-size: 34px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 40px;
                    width: 25%;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $light;



                    .text {
                        font-size: 12px;
                        color: $light;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .statisticthisyears {
            display: flex;
            background-color: $light;
            justify-content: center;
            width: 100%;
            padding: 20px 20px 20px 20px;
            flex-direction: column;

            .title {
                display: flex;
                margin-bottom: 40px;
                padding-top: 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                font-family: PLAY-BOLD;
                color: $black;
                font-size: 34px;
            }

            .wrapper_block {
                display: flex;
                justify-content: center;
                justify-content: space-between;
                flex-wrap: wrap;

                .block {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    font-size: 40px;
                    padding-left: 2%;
                    font-family: PLAY-BOLD;
                    color: $black;
                    width: 25%;

                    .text {
                        font-size: 12px;
                        color: $black;
                        font-family: PLAY-REGULAR;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }

        }

        .wrapper_achieved {
            padding-bottom: 30px;

            .achieved {
                flex-direction: column;
                padding: 20px 10px 20px 10px;

                .experience {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 0%;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 20px;
                    }

                    .wrapper_awards {
                        justify-content: center;
                        display: flex;
                        margin-left: 0%;
                        margin-right: 0%;
                        padding-bottom: 30px;

                        .block_awards {
                            width: 20%;
                            height: 100px;
                            gap: 1rem;
                            text-align: center;
                            border-radius: 6px;
                            justify-content: center;
                            align-items: center;
                            font-size: 4svh;
                            font-family: PLAY-BOLD;
                            color: $light;
                            text-align: center;

                            .text {
                                font-family: PLAY-REGULAR;
                                color: $light;
                                padding-top: 20px;
                                padding-bottom: 0px;
                                text-align: center;
                                justify-content: center;
                                padding-left: 5%;
                                padding-right: 5%;
                                font-size: 12px;
                                align-items: center;
                            }
                        }
                    }
                }

                .education {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        padding-top: 20px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 40px;
                    height: 100%;

                    .text {
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .year {
                            display: flex;
                            font-family: PLAY-BOLD;
                            color: $light;
                            width: 25%;
                            font-size: 25px;
                            padding-right: 100px;
                        }

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .photo {
            .block {
                .img-container {
                    img {
                        height: 200px;
                        margin: 5px;
                    }
                }

                .pop-up {
                    img {
                        width: 80%;
                    }
                }
            }
        }

        .wrapper_internships_courses {
            display: flex;
            font-size: 14px;
            background-color: $gray_black;

            .internships_courses {
                display: flex;
                padding: 20px 20px 0px 20px;
                justify-content: center;
                text-align: left;
                width: 100%;
                flex-direction: column;

                .internships {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        margin-bottom: 40px;
                        padding-top: 10px;
                        padding-bottom: 20px;
                        text-align: center;
                    }
                }

                .courses {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    padding-left: 5%;

                    .title {
                        padding-top: 10px;
                        font-size: 40px;
                        color: $gold;
                        font-family: PLAY-BOLD;
                        text-align: center;
                    }
                }

                .block {
                    display: flex;
                    width: 100%;
                    padding-bottom: 20px;
                    height: 100%;

                    .year {
                        display: flex;
                        font-family: PLAY-BOLD;
                        color: $light;
                        width: 25%;
                        font-size: 25px;
                        padding-right: 20px;
                    }

                    .text {
                        flex-direction: column;
                        display: flex;
                        padding-left: 20px;
                        width: 75%;

                        .company {
                            font-family: PLAY-BOLD;
                            color: $light;
                            font-size: 25px;
                        }

                        .post {
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            color: $black;
                            margin-top: 10px;

                            p {
                                display: inline-block;
                                padding: 10px;
                                background: $gold;
                            }
                        }

                        .description {
                            font-family: PLAY-REGULAR;
                            color: $gray_light;
                            display: flex;
                            margin-top: 10px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                }
            }
        }

    }
}