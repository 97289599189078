@media (max-width: 1024px) {
    .banner {
        height: 650px;

        .content {
            .text {
                font-size: 65px;
            }

            .button_wrapper {
                display: flex;
                height: 75px;
                padding-top: 25px;

                .white_button {
                    padding-right: 10px;

                    button {
                        font-size: 18px;
                        padding: 10px 20px;
                    }
                }

                .dark_button {

                    button {
                        font-size: 18px;
                        padding: 10px 20px;
                    }

                }
            }
        }
    }

    .about_the_team {

        .about_the_team_wrapper {
            flex-direction: column;


            .index_text_block {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 0px;
                padding-right: 40px;
                padding-left: 40px;

                .title {
                    text-align: center;
                }

                .text {
                    text-align: center;
                }

                .about_button {
                    text-align: center;
                }
            }

            .index_photo_block {
                width: 100%;
            }
        }
    }

    .view_in_team {
        height: 450px;

        .team_88 {
            .content {
                .text {
                    font-size: 95px;
                }
            }
        }

        .team_pro {
            .content {
                .text {
                    font-size: 95px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .banner {
        height: 650px;

        .content {
            height: 400px;

            .text {
                font-size: 35px;
            }

            .button_wrapper {
                display: flex;
                flex-direction: column;
                height: 150px;
                padding-top: 25px;

                .white_button {
                    padding-right: 0px;

                    button {
                        font-size: 18px;
                        padding: 10px 20px;
                        min-width: 230px;
                    }
                }

                .dark_button {
                    padding-top: 10px;

                    button {
                        font-size: 18px;
                        padding: 10px 20px;
                        min-width: 230px;
                    }

                }
            }
        }
    }

    .last_matches {
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 15px;

        .title {
            margin-bottom: 15px;
            text-align: center;
        }

        .last_match_8x8 {
            height: 230px;

            .fill {
                .data {
                    text-align: center;
                    padding-top: 30px;
                    font-size: 14px;
                    color: $light;
                    font-family: play-regular;
                }

                .title_last_matches {
                    padding-top: 10px;
                    font-size: 14px;
                }

                .text_block {
                    align-items: center;
                    padding: 10px;

                    .team_name_1 {
                        font-size: 16px;
                    }

                    .team_name_2 {
                        font-size: 16px;
                    }
                }

                .goals {
                    font-size: 16px;
                    height: 30%;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .last_match_pro {
            height: 230px;

            .fill {
                .data {
                    text-align: center;
                    padding-top: 30px;
                    font-size: 14px;
                    color: $light;
                    font-family: play-regular;
                }

                .title_last_matches {
                    padding-top: 10px;
                    font-size: 14px;
                }

                .text_block {
                    align-items: center;
                    padding: 10px;

                    .team_name_1 {
                        font-size: 16px;
                    }

                    .team_name_2 {
                        font-size: 16px;
                    }
                }

                .goals {
                    height: 30%;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                }
            }
        }
    }

    .about_the_team {

        .about_the_team_wrapper {
            flex-direction: column;


            .index_text_block {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 0px;
                padding-right: 20px;
                padding-left: 20px;

                .title {
                    text-align: center;
                    font-size: 35px;
                }

                .text {
                    text-align: center;
                    font-size: 20px;
                }

                .about_button {
                    text-align: center;
                }
            }

            .index_photo_block {
                width: 100%;

                .big_photo {
                    height: 100%;
                    width: 100%;
                    padding: 20px 10px 5px 10px;
                }

                .smalll_photo {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    padding: 5px 10px 0px 10px;
                    flex-direction: column;

                    img {
                        height: 100%;
                        width: 100%;
                        border-radius: 3px;
                        padding-bottom: 10px;
                    }

                    :last-child {
                        padding-left: 00px;
                    }
                }
            }
        }
    }

    .view_in_team {
        height: auto;
        flex-direction: column;

        .team_88 {
            width: 100%;
            height: 400px;
        }

        .team_pro {
            width: 100%;
            height: 400px;
        }
    }

    .tablegoal {
        flex-direction: column;

        .last_matches {
            padding: 45px 15px;
            width: 100%;
            height: auto;

            .wrapper {
                max-width: 400px;
            }
        }

        .statistic {
            padding: 45px 15px;
            width: 100%;
            height: auto;

            .wrapper {
                width: 90%;
                max-width: 500px;

                .title {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 30px;
                    font-size: 22px;
                    font-family: PLAY-BOLD;
                }

                ul {
                    font-family: PLAY-REGULAR;

                    li {
                        display: flex;
                        padding: 5px 0;
                        justify-content: space-between;

                        .number {
                            display: flex;
                            justify-content: flex-start;
                        }

                        .player {
                            display: flex;
                        }

                        .counter {
                            display: flex;
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }

        .assists {
            background: $gray_black;
        }
    }

    .birthday {
        display: flex;
        height: 540px;
        margin: 10px 10px 10px 10px;

        .birthday_players {
            width: 100%;
        }

        .player_in_team {
            display: none;
        }
    }

    .scrolling-wrapper {
        overflow-x: auto;
        /* используем auto вместо scroll */
        overflow-y: hidden;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        /* Добавляем фиксированную ширину */
        -ms-overflow-style: none;
        /* убираем стандартный скролл для IE */
        scrollbar-width: none;
        /* убираем стандартный скролл для Firefox */
        scroll-behavior: smooth;
        /* делаем скролл плавным */
    }

    .scrolling-wrapper .card {
        display: inline-block;
        padding: 10px 20px;
        overflow-x: hidden;
        /* Добавляем скрытие по горизонтали для изображений */
    }

    .scrolling-wrapper .card img {
        height: auto;
        /* Установите высоту изображений в автоматический режим */
        max-height: 50vh;
        /* Установите максимальную высоту для изображений */
        cursor: grab;
        overflow-x: hidden;
        /* меняем курсор на grab при наведении на изображение */
    }

    /* меняем курсор на grabbing при перетаскивании изображения */
    .scrolling-wrapper .card img:active {
        cursor: grabbing;
        overflow-x: hidden;
    }

    .scrolling-wrapper::-webkit-scrollbar {
        overflow-x: hidden;
    }


}