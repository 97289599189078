@media (max-width: 1366px) {

    .hystory {
        display: flex;
        width: 100%;
        background-color: $dark;
        justify-content: center;
        padding: 170px 1em 0px 1em;
        flex-direction: column;
        font-family: PLAY-REGULAR;
        color: $black;
        align-items: center;

        .timeline {
            max-width: 5000px;

            .container-timeline {
                width: 80%;
            }
        }
    }
}

@media (max-width: 1024px) {

    .hystory {
        display: flex;
        width: 100%;
        background-color: $dark;
        justify-content: center;
        padding: 70px 1em 0px 1em;
        flex-direction: column;
        font-family: PLAY-REGULAR;
        color: $black;
        align-items: center;

        .timeline {
            max-width: 5000px;

            .container-timeline {
                width: 100%;

                .text-box {
                    padding: 5px 15px;
                }
            }
        }
    }
}


@media (max-width: 768px) {}