@media (max-width: 1366px) {
    .result {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 190px 0 0px 0;
        flex-direction: column;

        .title {}

        .matches_result {

            .match {
                display: flex;
                width: 24%;
            }

            .match:hover {}
        }
    }
}

@media (max-width: 1024px) {
    .result {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 170px 0 0px 0;
        flex-direction: column;

        .title {}

        .matches_result {

            .match {
                display: flex;
                width: 32%;
                height: 150px;
            }

            .match:hover {}
        }
    }
}

@media (max-width: 768px) {
    .result {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 90px 0 0px 0;
        flex-direction: column;

        .title {}

        .matches_result {

            .match {
                display: flex;
                width: 100%;
                height: 150px;
            }

            .match:hover {}
        }
    }
}