.hystory {
    display: flex;
    width: 100%;
    background-color: $dark;
    justify-content: center;
    padding: 190px 2em 0px 2em;
    flex-direction: column;
    font-family: PLAY-REGULAR;
    color: $black;
    align-items: center;

    .timeline {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 4000px;
        background: $dark;
        justify-content: center;
        align-items: center;
        padding-top: 10px;

        .container-timeline {
            padding: 10px 10px;
            display: flex;
            width: 70%;
            height: 100%;
            background: $light;
            flex-direction: column;
            border-radius: 6px;
            margin-bottom: 10px;
            -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
            box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);

            .photo {
                background-color: $light;
                height: 40%;
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    margin: 20px 30px;
                    max-width: 1000px;
                    min-width: 280px;
                    border-radius: 6px;
                    transition: 1s;
                    -webkit-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                    -moz-box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                    box-shadow: 0px 0px 16px 2px rgba(34, 60, 80, 0.2);
                }

                img:hover {
                    transform: scale(1.05);
                }
            }

            .text-box {
                flex-direction: column;
                display: flex;
                padding: 10px 30px;
                font-size: 15px;
                background-color: $light;

                h2 {
                    font-size: 30px;
                    font-family: PLAY-BOLD;
                    padding-bottom: 10px;
                }

                small {
                    font-size: 14px;
                    font-family: PLAY-REGULAR;
                    padding-bottom: 10px;
                    color: $gray
                }

                p {
                    font-size: 16px;
                    font-family: PLAY-REGULAR;
                    padding-bottom: 10px;
                }
            }
        }
    }

}


@import "media/_hystory.scss";