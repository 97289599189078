@media (max-width: 1366px) {
    .player_block {
        align-content: center;

        .title_player_block {

            .title_team_name {}

            .title_page_name {}
        }

        .player {
            .photo_player {
                width: 20%;

                img {
                    height: 500px;
                }
            }

            .information_player {
                width: 40%;
                margin-left: 80px;
                margin-right: 0px;

                .biography {
                    width: 100%;
                    padding-top: 25px;

                    .text {
                        font-size: 16px;
                        font-family: PLAY-REGULAR;
                        color: $light;
                        padding-top: 10px;
                        padding-bottom: 20px;
                        padding-right: 0px;
                    }
                }
            }

            .slider {
                width: 30%;
                height: 100%;
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 1024px) {

    .banner_hall {
        height: 1000px;

        .content_banner {
            left: 50%;
            right: 0%;
            top: 35%;
            align-items: center;
            justify-content: center;


            .title_banner {
                text-align: center;
                display: flex;
                justify-content: center;
            }

            .title_2_banner {
                text-align: center;
            }

            .text_banner {
                width: 100%;
                text-align: center;
            }
        }

        .photo_block {
            left: 45%;
            right: 45%;
            top: 90%;

            .player {
                margin-left: 70px;
                width: 15%;
            }
        }

        .photo_block_2 {
            left: 45%;
            right: 45%;
            top: 70%;

            .player {
                margin-left: 70px;
                width: 15%;
            }
        }
    }

    .player_block {
        align-content: center;

        .title_player_block {

            .title_team_name {}

            .title_page_name {}
        }

        .player {
            flex-direction: column;

            .photo_player {
                width: 0%;
                background-color: $black;
                opacity: 0.3;
                z-index: 0;
                position: absolute;

                img {
                    height: 500px;
                }
            }

            .information_player {
                width: 95%;
                margin-left: 20px;
                margin-right: 0px;
                z-index: 1;
                position: relative;

                .biography {
                    width: 100%;
                    padding-top: 25px;

                    .text {
                        font-size: 16px;
                        font-family: PLAY-REGULAR;
                        color: $light;
                        padding-top: 10px;
                        padding-bottom: 20px;
                        padding-right: 0px;
                    }
                }
            }

            .slider {
                width: 95%;
                height: 100%;
                margin-left: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .banner_hall {
        height: 1800px;

        .content_banner {
            left: 50%;
            right: 0%;
            top: 15%;
            align-items: center;
            justify-content: center;
            width: 90%;

            .title_banner {
                text-align: center;
                font-size: 50px;
            }

            .title_2_banner {
                text-align: center;
                font-size: 20px;
            }

            .text_banner {
                width: 100%;
                text-align: center;
                width: 80%;
            }
        }

        .photo_block {
            left: 50%;
            right: 50%;
            top: 700px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            text-align: center;
            align-items: center;

            .player {
                margin-right: 40px;
                margin-left: 40px;
                width: 200px;


                .image {
                    margin-bottom: 10px;
                    margin-top: 10px;
                }

                .name {
                    font-size: 18px;
                }
            }

        }

        .photo_block_2 {
            left: 50%;
            right: 50%;
            top: 1325px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            text-align: center;
            align-items: center;

            .player {
                margin-right: 40px;
                margin-left: 40px;
                width: 200px;


                .image {
                    margin-bottom: 10px;
                    margin-top: 10px;
                }

                .name {
                    font-size: 18px;
                }
            }

        }
    }

    .player_block {

        .title_player_block {

            .title_team_name {
                font-size: 16px;
                padding-left: 10px;
            }

            .title_page_name {
                font-size: 18px;
                padding-left: 10px;
            }
        }

        .player {
            .information_player {
                width: 95%;
                margin-left: 10px;
                margin-right: 0px;
                z-index: 1;
                position: relative;

                .biography {
                    width: 100%;
                    padding-top: 25px;

                    .text {
                        font-size: 16px;
                        font-family: PLAY-REGULAR;
                        color: $light;
                        padding-top: 10px;
                        padding-bottom: 20px;
                        padding-right: 0px;
                    }
                }
            }

            .slider {
                width: 100%;
                height: 100%;
                margin-left: 0px;

                .container_slider_css {
                    width: 100%;
                }
            }
        }
    }
}