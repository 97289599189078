@font-face {
    font-family: PLAY-BOLD;
    src: url("../fonts/PLAY-BOLD.TTF");
}

@font-face {
    font-family: PLAY-REGULAR;
    src: url("../fonts/PLAY-REGULAR.TTF");
}

@font-face {
    font-family: DINPRO-BLACK;
    src: url("../fonts/DINPRO-BLACK.OTF");
}

@font-face {
    font-family: DINPRO-REGULAR;
    src: url("../fonts/DINPRO-REGULAR.OTF");
}