@media (max-width: 1366px) {
    .wrapper_records {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 170px 0 30px 0;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .container-statistic {
            display: flex;
            flex-direction: column;

            .title {
                display: flex;
                font-size: 30px;
                color: $light;
                margin-bottom: 10px;
                font-family: PLAY-BOLD;
                justify-content: center;
                margin-top: 20px;
            }

            .blocks_matches {
                display: flex;
                height: 240px;
                margin-bottom: 10px;
                width: 100%;
                justify-content: center;

                .block {
                    display: flex;
                    height: 100%;
                    width: 15%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;


                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_wins {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_draw {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_lose {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value4 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                    }
                }

                .block_2 {
                    display: flex;
                    height: 100%;
                    width: 20%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_2:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_3 {
                    display: flex;
                    height: 100%;
                    width: 20%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_3:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_4 {
                    display: flex;
                    height: 100%;
                    width: 20%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_5 {
                    display: flex;
                    height: 100%;
                    width: 20%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .big_block {
                    display: flex;
                    height: 100%;
                    width: 40%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .big_block {
                    display: flex;
                    height: 100%;
                    width: 40%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 30px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 20%;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;

            .block {
                display: flex;
                height: 220px;
                width: 33%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }

                    .img {
                        display: flex;
                        width: 60%;
                        height: 150px;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 150px;
                            padding-left: 20px;

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .wrapper_records {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 170px 0 30px 0;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .container-statistic {
            display: flex;
            flex-direction: column;

            .title {
                display: flex;
                font-size: 30px;
                color: $light;
                margin-bottom: 10px;
                font-family: PLAY-BOLD;
                justify-content: center;
                margin-top: 20px;
            }

            .blocks_matches {
                display: flex;
                height: 100%;
                margin-bottom: 10px;
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;

                .block {
                    display: flex;
                    height: 240px;
                    width: 23%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;


                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_wins {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_draw {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_lose {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value4 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                    }
                }

                .block_2 {
                    display: flex;
                    height: 240px;
                    width: 30%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_2:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_3 {
                    display: flex;
                    height: 240px;
                    width: 30%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_3:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_4 {
                    display: flex;
                    height: 240px;
                    width: 30%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_5 {
                    display: flex;
                    height: 240px;
                    width: 30%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 70px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .big_block {
                    display: flex;
                    height: 240px;
                    width: 40%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .big_block {
                    display: flex;
                    height: 240px;
                    width: 100%;
                    margin: 10px 15px 10px 15px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 30px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 20%;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 220px;
                width: 100%;
                margin: 10px 0px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .wrapper_records {
        display: flex;
        width: 100%;
        background-color: $gray_black;
        justify-content: center;
        padding: 70px 0 30px 0;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        .container-statistic {
            display: flex;
            flex-direction: column;

            .title {
                display: flex;
                font-size: 20px;
                color: $light;
                margin-bottom: 10px;
                font-family: PLAY-BOLD;
                justify-content: center;
                margin-top: 20px;
                text-align: center;
            }

            .blocks_matches {
                display: flex;
                height: 100%;
                margin-bottom: 10px;
                width: 100%;
                justify-content: center;
                flex-wrap: wrap;

                .block {
                    display: flex;
                    height: 240px;
                    width: 45%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;


                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_wins {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_draw {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .title_block_lose {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px 10px 20px 10px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                    }

                    .value4 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                    }
                }

                .block_2 {
                    display: flex;
                    height: 240px;
                    width: 45%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 90px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $light;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_2:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_3 {
                    display: flex;
                    height: 240px;
                    width: 45%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $pink;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 90px;
                        color: $light;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_3:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_4 {
                    display: flex;
                    height: 240px;
                    width: 45%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $green;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 90px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .block_5 {
                    display: flex;
                    height: 240px;
                    width: 45%;
                    margin: 10px 5px 20px 5px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_matches {
                        display: flex;
                        background-color: $gray;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 80%;
                        height: 90px;
                        color: $black;
                        align-items: center;
                        font-size: 16px;
                        text-align: center;
                        padding: 5px;
                    }

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        margin: 0px 20px 20px 20px;
                        justify-content: center;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .block_4:last-child {
                    margin: 10px 0px 10px 10px;
                }

                .big_block {
                    display: flex;
                    height: 300px;
                    width: 40%;
                    margin: 10px 0px 10px 10px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 25px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 16px;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                    }
                }

                .big_block {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    margin: 10px 15px 10px 15px;
                    background-color: rgba(0, 0, 0, 0.4);
                    border-radius: 6px;
                    justify-content: start;
                    flex-direction: column;
                    align-items: center;

                    .title_block_big_block {
                        display: flex;
                        background-color: $dark_light;
                        border-radius: 6px;
                        font-family: PLAY-BOLD;
                        margin: 20px;
                        font-family: PLAY-BOLD;
                        justify-content: center;
                        width: 70%;
                        height: 30px;
                        color: $light;
                        align-items: center;
                        text-align: center;
                    }

                    .text {
                        display: flex;
                        height: 20%;
                        color: $light;
                        justify-content: center;
                        font-family: PLAY-REGULAR;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .blocks_goals {
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

            .block {
                display: flex;
                height: 220px;
                width: 100%;
                margin: 10px 10px 0px 10px;
                background-color: rgba(0, 0, 0, 0.4);
                border-radius: 6px;
                justify-content: start;
                flex-direction: column;
                align-items: center;


                .title_goals {
                    display: flex;
                    background-color: $green;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $black;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_lose {
                    display: flex;
                    background-color: $pink;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }

                .title_goals_difference {
                    display: flex;
                    background-color: $gray;
                    border-radius: 6px;
                    font-family: PLAY-BOLD;
                    margin: 20px;
                    font-family: PLAY-BOLD;
                    justify-content: center;
                    width: 60%;
                    height: 25px;
                    color: $light;
                    align-items: center;
                    font-size: 16px;
                }


                .container_goal {
                    display: flex;
                    width: 100%;
                    height: 100%;

                    .value {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $green;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value2 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $pink;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                    }

                    .value3 {
                        display: flex;
                        font-family: PLAY-BOLD;
                        justify-content: left;
                        align-items: center;
                        color: $gray;
                        font-size: 50px;
                        padding-top: 10px;
                        width: 40%;
                        height: 150px;
                        align-items: center;
                    }
                }
            }
        }
    }
}