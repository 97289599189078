@media (max-width: 1366px) {
    .pro11x11_wrapper {
        .selection_players {
            .team {
                .team_name {
                    .name_team {}

                    .button {
                        button {}

                        button:hover {}
                    }
                }

                .players {
                    .player {
                        font-size: 20px;

                        .number {}

                        .name {}
                    }

                    .player:hover {}
                }

                .player_blok {
                    .goalkeeper {

                        .title {
                            font-size: 20px;
                        }
                    }

                    .defender {
                        .title {
                            font-size: 20px;
                        }
                    }
                }

                .midfielders {
                    .title {
                        font-size: 20px;
                    }
                }

                .forvards_coach_block {}

                .forvards {

                    .title {
                        font-size: 20px;
                    }
                }

                .coach {

                    .title {
                        font-size: 20px;

                    }

                    button {}

                    button:hover {}
                }
            }
        }

        .statistics {
            .title {
                padding: 20px;
                height: 50px;

                .title_text {
                    font-size: 24px;
                    color: $dark_light;
                    width: 95%;
                    padding-left: 30px;
                }
            }

            .blocks_statistic {
                flex-direction: column;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                overflow: hidden;

                .wrapper {
                    display: flex;
                    transition: transform 0.5s ease;

                    .slider {
                        display: flex;
                        width: 100%;

                        .blocks {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 0 20px 20px 20px;
                        }

                        .game {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .wins {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .draw {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .goals {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .goals_conceded {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .difference {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .form {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .wins_series {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .wins_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }


                        .the_best_win {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }

                        .the_best_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 20px;
                        }


                        .text {
                            justify-content: start;
                            flex-direction: column;
                            width: 70%;
                            height: 100%;

                            .name {
                                font-size: 15px;
                                color: $gray;
                                text-align: left;
                                padding-bottom: 5px;
                            }

                            .number {
                                font-size: 20px;
                                color: $light;
                                text-align: left;
                            }
                        }

                        .icon {
                            display: flex;
                            height: 100%;
                            width: 15%;
                            justify-content: center;
                            margin-right: 20px;

                            img {
                                height: 50px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .tablegoal {
            width: 100%;
            display: flex;
            font-size: 16px;

            .statistic:nth-child(1) {
                background-color: $dark;
            }

            .statistic:nth-child(2) {
                background-color: $gray_black;
            }

            .statistic:nth-child(3) {
                background-color: $dark;
            }

            .statistic:nth-child(4) {
                background-color: $gray_black;
            }

            .statistic {
                background: red;
                display: flex;
                justify-content: center;
                align-items: start;
                padding: 5px 10px;
                color: $light;
                text-align: center;
                width: 25%;
                height: 250px;

                .wrapper {
                    width: 90%;
                    max-width: 200px;

                    .title {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 30px;
                        font-size: 22px;
                        font-family: PLAY-BOLD;
                        height: 75px;
                        padding-top: 20px;
                    }

                    ul {
                        font-family: PLAY-REGULAR;
                        height: 90%;

                        li {
                            display: flex;
                            padding: 5px 0;
                            justify-content: space-between;

                            .number {
                                display: flex;
                                justify-content: flex-start;
                                width: 20px;
                            }

                            .player {
                                display: flex;
                            }

                            .counter {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .pro11x11_wrapper {
        .selection_players {
            .team {
                .team_name {
                    height: 110px;

                    .name_team {
                        font-size: 24px;
                        align-items: center;
                        padding-left: 15px;
                    }

                    .button {
                        flex-direction: column;
                        padding-left: 23%;

                        button {
                            width: 200px;
                        }

                        button:hover {}
                    }
                }

                .players {
                    .player {
                        font-size: 15px;

                        .number {}

                        .name {}
                    }

                    .player:hover {}
                }

                .player_blok {
                    padding: 10px;

                    .goalkeeper {

                        .title {
                            font-size: 20px;
                        }
                    }

                    .defender {

                        .title {
                            font-size: 20px;
                        }
                    }
                }

                .midfielders {
                    padding: 10px;

                    .title {
                        font-size: 20px;
                    }
                }

                .forvards_coach_block {
                    padding: 10px;
                }

                .forvards {

                    .title {
                        font-size: 20px;
                    }
                }

                .coach {

                    .title {
                        font-size: 20px;

                    }

                    button {}

                    button:hover {}
                }
            }
        }

        .statistics {
            .title {
                padding: 20px;
                padding-left: 30px;
                height: 50px;

                .title_text {
                    font-size: 25px;
                    color: $dark_light;
                    width: 95%;
                    padding-left: 10px;
                }
            }

            .blocks_statistic {
                flex-direction: column;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                overflow: hidden;

                .wrapper {
                    display: flex;
                    transition: transform 0.5s ease;

                    .slider {
                        display: flex;
                        width: 100%;

                        .blocks {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 0 10px 10px 10px;
                        }

                        .game {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .wins {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .draw {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .goals {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .goals_conceded {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .difference {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .form {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .wins_series {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .wins_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }


                        .the_best_win {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }

                        .the_best_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                        }


                        .text {
                            justify-content: start;
                            flex-direction: column;
                            width: 70%;
                            height: 100%;

                            .name {
                                font-size: 14px;
                                color: $gray;
                                text-align: left;
                                padding-bottom: 5px;
                            }

                            .number {
                                font-size: 15px;
                                color: $light;
                                text-align: left;
                            }
                        }

                        .icon {
                            display: flex;
                            height: 100%;
                            width: 15%;
                            justify-content: center;
                            margin-right: 20px;

                            img {
                                height: 50px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .tablegoal {
            width: 100%;
            display: flex;
            font-size: 16px;

            .statistic {
                background: red;
                display: flex;
                justify-content: center;
                align-items: start;
                padding: 5px 10px;
                color: $light;
                text-align: center;
                width: 100%;
                height: 250px;

                .wrapper {
                    width: 90%;
                    max-width: 200px;

                    .title {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 30px;
                        font-size: 22px;
                        font-family: PLAY-BOLD;
                        height: 75px;
                        padding-top: 20px;
                    }

                    ul {
                        font-family: PLAY-REGULAR;
                        height: 90%;

                        li {
                            display: flex;
                            padding: 5px 0;
                            justify-content: space-between;

                            .number {
                                display: flex;
                                justify-content: flex-start;
                                width: 20px;
                            }

                            .player {
                                display: flex;
                            }

                            .counter {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .pro11x11_wrapper {
        .selection_players {
            margin-top: 80px;

            .team {
                .team_name {
                    padding: 5px;
                    align-items: center;
                    margin-bottom: 20px;

                    .name_team {
                        font-size: 20px;
                        align-items: center;
                        padding-left: 25px;
                        width: 50%;
                    }

                    .button {
                        flex-direction: column;
                        width: 50%;
                        padding-left: 15%;
                        margin-bottom: 10px;

                        button {
                            width: 100%;
                            font-size: 14px;
                            justify-content: center;
                            font-size: 14px;
                            padding: 5px 3px;
                        }

                        button:hover {}
                    }

                    .button_8x8 {
                        flex-direction: column;
                        width: 50%;
                        padding-left: 15%;
                        margin-bottom: 10px;

                        button {
                            width: 100%;
                            font-size: 14px;
                            justify-content: center;
                            font-size: 14px;
                            padding: 5px 3px;
                        }
                    }
                }

                .players {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .player {
                        font-size: 20px;


                        .number {
                            text-align: center;
                        }

                        .name {}
                    }

                    .player:hover {}
                }

                .player_blok {
                    padding: 10px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0px;
                    padding-right: 0px;

                    .goalkeeper {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        text-align: center;

                        .title {
                            font-size: 25px;
                            text-align: center;
                            padding-left: 0px;
                        }

                        .player {
                            display: flex;
                            text-align: left;
                            justify-content: center;
                            align-items: center;
                            padding-left: 0px;
                            width: 60%;

                            .number {
                                text-align: right;
                                margin-right: 10px;
                            }

                            .name {
                                text-align: right;
                            }
                        }
                    }

                    .defender {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        text-align: center;
                        flex-direction: column;
                        padding-left: 0px;
                        padding-right: 0px;

                        .title {
                            font-size: 25px;
                            text-align: center;
                            padding-left: 0px;
                        }

                        .player {
                            display: flex;
                            text-align: left;
                            justify-content: center;
                            align-items: center;
                            padding-left: 0px;
                            width: 60%;

                            .number {
                                text-align: right;
                                margin-right: 10px;
                            }

                            .name {
                                text-align: right;
                            }
                        }
                    }
                }

                .midfielders {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    text-align: center;
                    width: 100%;
                    padding-left: 0px;
                    padding-right: 0px;

                    .title {
                        font-size: 25px;
                        text-align: center;
                        padding-left: 0px;
                    }

                    .player {
                        display: flex;
                        text-align: left;
                        justify-content: center;
                        align-items: center;
                        padding-left: 0px;
                        width: 60%;

                        .number {
                            text-align: right;
                            margin-right: 10px;
                        }

                        .name {
                            text-align: right;
                        }
                    }

                }

                .forvards_coach_block {
                    padding: 10px;
                    width: 100%;
                    display: flex;
                    padding-left: 0px;
                    padding-right: 0px;
                }

                .forvards {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    text-align: center;

                    .title {
                        font-size: 25px;
                        text-align: center;
                        padding-left: 0px;
                    }

                    .player {
                        display: flex;
                        text-align: left;
                        justify-content: center;
                        align-items: center;
                        padding-left: 0px;
                        width: 60%;

                        .number {
                            text-align: right;
                            margin-right: 10px;
                        }

                        .name {
                            text-align: right;
                        }
                    }
                }

                .coach {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    text-align: center;
                    margin-top: 30px;

                    .title {
                        font-size: 25px;
                        text-align: center;
                        padding-left: 0px;
                    }

                    .player {
                        display: flex;
                        text-align: left;
                        justify-content: center;
                        align-items: center;
                        padding-left: 0px;
                        width: 60%;

                        .number {
                            text-align: right;
                            margin-right: 10px;
                        }

                        .name {
                            text-align: center;
                        }
                    }

                    button {}

                    button:hover {}
                }
            }
        }

        .statistics {

            justify-content: center;
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                padding: 20px;
                padding-left: 22px;
                height: 50px;
                justify-content: center;
                align-items: center;
                display: flex;
                width: 100%;

                .title_text {
                    display: flex;
                    font-size: 25px;
                    color: $dark_light;
                    width: 95%;
                    padding-left: 10px;
                    justify-content: center;
                    align-items: center;
                }
            }

            .blocks_statistic {
                flex-direction: column;
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                overflow: hidden;
                justify-content: center;
                align-items: center;

                .wrapper {
                    display: flex;
                    transition: transform 0.5s ease;
                    justify-content: center;
                    align-items: center;

                    .slider {
                        flex-direction: row;
                        /* горизонтальное расположение блоков */
                        overflow-x: auto;
                        /* горизонтальный скролл */
                        -webkit-overflow-scrolling: touch;
                        /* плавный скроллинг на мобильных устройствах */
                        padding-left: 0;
                        padding-right: 0;
                        justify-content: none;
                        flex-wrap: nowrap;
                        scrollbar-width: none;
                        /* для Firefox */

                        .blocks {
                            flex: 0 0 auto;
                            /* предотвращаем сжатие блоков */
                            width: auto;
                            /* для удобства скроллинга убираем фиксированную ширину */
                            min-width: 25svh;
                            /* минимальная ширина блока, чтобы они не становились слишком узкими */
                            margin: 5px;
                            /* добавляем отступы между блоками */
                            justify-content: center;
                        }

                        .game {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .wins {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .draw {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .goals {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .goals_conceded {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .difference {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .form {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            justify-content: center;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;
                        }

                        .wins_series {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;

                            .icon {
                                width: 25%;
                            }
                        }

                        .wins_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;

                            .icon {
                                width: 25%;
                            }
                        }


                        .the_best_win {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;

                            .icon {
                                width: 25%;
                            }
                        }

                        .the_best_lose {
                            display: flex;
                            width: 100%;
                            height: 100%;
                            margin-bottom: 10px;
                            padding: 10px;
                            min-height: 100px;
                            align-items: center;

                            .icon {
                                width: 25%;
                            }
                        }


                        .text {
                            justify-content: start;
                            flex-direction: column;
                            width: 50%;
                            height: 100%;

                            .name {
                                font-size: 18px;
                                color: $gray;
                                text-align: left;
                                padding-bottom: 5px;
                            }

                            .number {
                                font-size: 20px;
                                color: $light;
                                text-align: left;
                            }
                        }

                        .icon {
                            display: flex;
                            height: 100%;
                            width: 50%;
                            justify-content: right;
                            margin-right: 20px;
                            align-items: center;

                            img {
                                height: 50px;
                                width: 100%;
                                justify-content: right;
                            }
                        }
                    }
                }
            }
        }

        .tablegoal {
            width: 100%;
            display: flex;
            font-size: 16px;
            padding-bottom: 5px;

            .block-wrapper {
                display: flex;
                flex-direction: row;
                /* горизонтальное расположение блоков */
                overflow-x: auto;
                /* горизонтальный скролл */
                -webkit-overflow-scrolling: touch;
                /* плавный скроллинг на мобильных устройствах */
                padding-left: 0;
                padding-right: 0;
                justify-content: start;
                flex-wrap: nowrap;
                scrollbar-width: none;
                /* для Firefox */
                font-size: 16px;
                padding-left: 5px;

                .statistic {
                    background: red;
                    display: flex;
                    justify-content: center;
                    align-items: start;
                    padding: 5px 10px;
                    color: $light;
                    text-align: center;
                    width: 100%;
                    height: 265px;
                    flex: 0 0 auto;
                    /* предотвращаем сжатие блоков */
                    width: auto;
                    /* для удобства скроллинга убираем фиксированную ширину */
                    min-width: 25svh;
                    /* минимальная ширина блока, чтобы они не становились слишком узкими */
                    margin: 5px;
                    /* добавляем отступы между блоками */
                    min-height: 275px;
                    min-width: 75%;

                    .wrapper {
                        width: 90%;
                        max-width: 200px;

                        .title {
                            display: flex;
                            justify-content: center;
                            padding-bottom: 30px;
                            font-size: 22px;
                            font-family: PLAY-BOLD;
                            height: 75px;
                            padding-top: 20px;
                        }

                        ul {
                            font-family: PLAY-REGULAR;
                            height: 90%;
                            padding-bottom: 25px;

                            li {
                                display: flex;
                                padding: 5px 0;
                                justify-content: space-between;

                                .number {
                                    display: flex;
                                    justify-content: flex-start;
                                    width: 20px;
                                }

                                .player {
                                    display: flex;
                                }

                                .counter {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}