@media (max-width: 1366px) {
    .literature {

        .about_autor {
            justify-content: center;
            flex-direction: column;

            .image_autor {
                padding: 0%;
                height: 0%;
                width: 0%;

                img {
                    height: 0%;
                    width: 0%;
                }
            }

            .text_about_autor {
                font-family: PLAY-REGULAR;
                font-size: 20px;
                color: $light;
                text-align: left;
                padding-top: 0px;
                height: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .literature {

        .about_autor {
            justify-content: center;
            flex-direction: column;

            .image_autor {
                padding: 0%;
                height: 0%;
                width: 0%;

                img {
                    height: 0%;
                    width: 0%;
                }
            }

            .text_about_autor {
                padding: 10px 20px 20px 20px;
            }
        }

        .books {
            .books_wrapper {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 0px;

                .book {
                    width: 100%;

                    &:last-child {
                        background: $gray_black;
                    }

                    .text_block {
                        .text {
                            padding-left: 20px;
                            padding-right: 20px;
                        }

                        .link {
                            padding-bottom: 20px;
                        }
                    }

                    .image {
                        height: 500px;
                    }
                }
            }
        }
    }
}