.personal_data {
    display: flex;
    width: 100%;
    background-color: $light;
    justify-content: center;
    padding: 230px 50px 60px 50px;
    flex-direction: column;
    justify-content: center;

    h1 {
        font-size: 38px;
        font-family: PLAY-BOLD;
        padding-bottom: 20px;
        text-align: center;
    }

    h2 {
        font-size: 24px;
        font-family: PLAY-REGULAR;
        padding-bottom: 20px;
        text-align: center;
    }

    .text {
        font-size: 20px;
        font-family: PLAY-REGULAR;
        padding-bottom: 20px;
        text-align: left;
    }


}

@import "media/_personal_data.scss";