@media (max-width: 1366px) {

    .gallery {
        .content {
            display: flex;
            height: 100%;
            width: 100%;
            margin-top: 170px;
        }
    }
}

@media (max-width: 1024px) {

    .gallery {
        .content {
            display: flex;
            height: 100%;
            margin-top: 150px;
        }
    }
}

@media (max-width: 768px) {
    .gallery {
        .content {
            margin-top: 70px;
        }
    }
}