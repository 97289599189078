.team8x8_wrapper {
    background-color: $black;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    font-family: PLAY-REGULAR;

    .selection_players {
        display: flex;
        height: 100%;
        width: 100%;
        margin-top: 190px;
        background-color: $black;
        flex-direction: column;

        .team {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .team_name {
                display: flex;
                height: 100px;
                width: 95%;
                background-color: $black;
                padding: 25px;
                border-bottom: 10px solid $dark;

                .name_team {
                    font-size: 30px;
                    color: $dark_light;
                    width: 50%;
                    padding-left: 20px;
                }

                .button_8x8 {
                    display: flex;
                    width: 50%;
                    justify-content: end;
                    padding-right: 20px;

                    button {
                        font-family: PLAY-REGULAR;
                        font-size: 16px;
                        color: $light;
                        background-color: $dark;
                        padding: 10px 5px;
                        border: solid $light 1px;
                        border-radius: 8px;
                        transition: 101ms;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        margin-left: 10px;
                    }

                    button:hover {
                        transition: 101ms;
                        padding: 10px 5px;
                        background-color: $black;
                        color: $light;
                        border: solid 1px $light;
                    }
                }
            }

            .players {
                display: flex;
                height: 100%;
                width: 95%;
                background-color: $black;
                justify-content: center;
                border-bottom: 10px solid $dark;

                .player {
                    display: flex;
                    font-size: 25px;
                    color: $light;
                    margin-bottom: 10px;
                    padding-left: 20px;

                    .number {
                        text-align: left;
                        margin-right: 10px;
                        width: 10%;
                    }

                    .name {
                        width: 90%;
                        text-align: left;

                        a:active,
                        a {
                            text-decoration: none;
                            color: $light;
                        }

                        a:hover {
                            color: $dark_light;
                        }
                    }
                }

                .player:hover {
                    color: $dark_light;
                    font-family: PLAY-BOLD;
                }
            }

            .player_blok {
                height: 100%;
                width: 33%;
                flex-direction: column;
                padding: 20px;


                .goalkeeper {
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        font-size: 25px;
                        color: $dark_light;
                        margin-bottom: 10px;
                        padding-left: 20px;
                    }
                }

                .defender {
                    .title {
                        font-size: 25px;
                        color: $dark_light;
                        margin-bottom: 10px;
                        margin-top: 20px;
                        padding-left: 20px;
                    }
                }
            }

            .midfielders {
                display: flex;
                height: 100%;
                width: 33%;
                flex-direction: column;
                padding: 20px;

                .title {
                    font-size: 25px;
                    color: $dark_light;
                    margin-bottom: 10px;
                    padding-left: 20px;
                }
            }

            .forvards_coach_block {
                display: flex;
                height: 100%;
                width: 33%;
                flex-direction: column;
                padding: 20px 20px 20px 0;
            }

            .forvards {
                display: flex;
                height: 100%;
                width: 100%;
                flex-direction: column;

                .title {
                    font-size: 25px;
                    color: $dark_light;
                    margin-bottom: 10px;
                    padding-left: 20px;
                }
            }

            .coach {
                display: flex;
                height: 100%;
                width: 100%;
                flex-direction: column;
                margin-top: 50px;

                .title {
                    font-size: 25px;
                    color: $dark_light;
                    margin-bottom: 10px;
                    padding-left: 20px;

                }

                button {
                    font-family: PLAY-REGULAR;
                    font-size: 18px;
                    color: $light;
                    background-color: $dark;
                    padding: 20px 5px;
                    border-radius: 0px;
                    border: solid $light 0px;
                    transition: 101ms;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    height: 13px;
                    width: 100%;
                }

                button:hover {
                    transition: 101ms;
                    padding: 20px 5px;
                    background-color: $black;
                    color: $light;
                }
            }
        }
    }

    .statistics {
        width: 95%;
        height: 100%;
        background-color: $black;
        justify-content: center;

        .title {
            display: flex;
            height: 100px;
            width: 95%;
            background-color: $black;
            padding: 30px;
            justify-content: center;

            .title_text {
                font-size: 30px;
                color: $dark_light;
                width: 95%;
                padding-left: 30px;
            }
        }

        .blocks_statistic {
            flex-direction: column;
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            overflow: hidden;

            .wrapper {
                display: flex;
                transition: transform 0.5s ease;

                .slider {
                    display: flex;
                    width: 100%;

                    .blocks {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 0 20px 20px 20px;
                    }

                    .game {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .wins {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .draw {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .lose {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .goals {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .goals_conceded {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .difference {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .form {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .wins_series {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .wins_lose {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }


                    .the_best_win {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }

                    .the_best_lose {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                        padding: 20px;
                    }


                    .text {
                        justify-content: start;
                        flex-direction: column;
                        width: 70%;
                        height: 100%;

                        .name {
                            font-size: 15px;
                            color: $gray;
                            text-align: left;
                            padding-bottom: 5px;
                        }

                        .number {
                            font-size: 20px;
                            color: $light;
                            text-align: left;
                        }
                    }

                    .icon {
                        display: flex;
                        height: 100%;
                        width: 10%;
                        justify-content: center;
                        margin-right: 20px;

                        img {
                            height: 50px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .tablegoal {
        width: 100%;
        display: flex;
        font-size: 16px;
        padding-bottom: 5px;

        .statistic:nth-child(1) {
            background-color: $dark;
        }

        .statistic:nth-child(2) {
            background-color: $gray_black;
        }

        .statistic:nth-child(3) {
            background-color: $dark;
        }

        .statistic:nth-child(4) {
            background-color: $gray_black;
        }

        .statistic:nth-child(5) {
            background-color: $dark;
        }

        .statistic {
            display: flex;
            justify-content: center;
            align-items: start;
            padding: 5px 10px;
            color: $light;
            text-align: center;
            width: 20%;
            border-radius: 6px;
            margin: 5px;

            .wrapper {
                width: 90%;
                max-width: 250px;
                padding-bottom: 10px;
                display: flex;
                height: 100%;

                .title {
                    display: flex;
                    justify-content: center;
                    padding-bottom: 30px;
                    font-size: 22px;
                    font-family: PLAY-BOLD;
                    height: 75px;
                    padding-top: 20px;
                }

                ul {
                    font-family: PLAY-REGULAR;
                    height: 90%;

                    li {
                        display: flex;
                        padding: 5px 0;
                        justify-content: space-between;

                        .number {
                            display: flex;
                            justify-content: flex-start;
                            width: 20px;
                        }

                        .player {
                            display: flex;
                        }

                        .counter {
                            display: flex;
                            justify-content: flex-end;
                            width: 30px;
                        }
                    }
                }

                .link {
                    display: flex;
                    justify-content: center;
                    padding-top: 5px;
                    font-family: PLAY-Bold;
                    align-items: end;

                    a {
                        font-size: 16px;
                        color: $light;
                        font-family: PLAY-BOLD;
                    }

                    a:hover {
                        color: $gray;
                    }
                }
            }
        }
    }
}

@import "media/_8x8.scss";