.testirovanie {
    background-color: $black;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    font-family: PLAY-REGULAR;
    align-items: center;

    .banner_testirovanie {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 794px;
        width: 100%;
        position: relative;

        .fill {
            position: absolute;
            top: 0;
            left: 0;
            background: black;
            opacity: .5;
            width: 100%;
            height: 100%;
        }

        .background_banner {
            background-image: url(../img/background_testing.jpg);
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .content_banner {
            position: absolute;
            left: 40%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-family: PLAY-BOLD;
            display: flex;
            flex-direction: column;
            justify-content: left;

            .title_banner {
                font-size: 95px;
                text-align: left;
                color: $light;
            }

            .title_2_banner {
                font-size: 40px;
                text-align: left;
                color: $light;
                margin-top: 15px;
            }

            .text_banner {
                font-size: 16px;
                text-align: left;
                font-family: PLAY-REGULAR;
                color: $light;
                margin-top: 25px;
                width: 55%;
            }

            @import "elements/_buttons.scss";
        }
    }

    .block_testing {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .title {
            display: flex;
            padding-left: 15px;
            padding-right: 15px;

            .title_block_testing {
                display: flex;
                width: 100%;
                justify-content: center;
                text-align: center;
                font-family: PLAY-REGULAR;
                color: $light;
                padding-left: 15px;
                padding-right: 15px;

                .name_test {
                    display: flex;
                    text-align: left;
                    width: 100%;
                    height: 100%;
                    font-size: 30px;
                    justify-content: center;
                    text-align: center;
                }

                .name_test:first-child {
                    padding-top: 15px;
                }

            }
        }

        .runing {
            height: 100%;
            display: flex;
            width: 100%;
            justify-content: center;
            align-content: flex-start;
            padding-bottom: 10px;

            .block {
                display: flex;
                flex-direction: column;
                width: 33%;
                height: 600px;
                margin: 20px 20px 0px 20px;
                padding: 20px;
                border-radius: 6px;
                background: $dark_medium;


                .title {
                    display: flex;
                    font-family: PLAY-Bold;
                    font-size: 35px;
                    align-items: center;
                    height: 20%;
                    justify-content: center;
                    text-align: left;
                    color: $light;
                    text-align: center;
                    padding-top: 10px;
                }

                .top_block {
                    text-align: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    height: 200px;
                    height: 40%;

                    .text_gold {
                        display: flex;
                        align-items: center;
                        color: $gold;
                        font-size: 25px;
                        padding-top: 8px;
                        justify-content: center;
                    }

                    .text {
                        display: flex;
                        align-items: center;
                        color: $light;
                        font-size: 25px;
                        padding-top: 8px;
                        justify-content: center;
                    }
                }

                .description {
                    display: flex;
                    padding-left: 15px;
                    padding-right: 15px;
                    color: $gray;
                    font-size: 18px;
                    justify-content: left;
                    text-align: left;
                    width: 100%;
                    padding-top: 20px;
                    height: 30%;

                }
            }

            .block:nth-child(1) {
                background: $dark;
                transform: translateX(-150%);
                animation: ani 1s forwards;

                @keyframes ani {
                    0% {
                        transform: translateX(-150%);
                    }

                    100% {
                        transform: translateY(0);
                    }
                }
            }

            .block:nth-child(2) {
                transform: translateX(-150%);
                animation: ani 1s forwards;

                @keyframes ani {
                    0% {
                        transform: translateX(-150%);
                    }

                    100% {
                        transform: translateY(0);
                    }
                }
            }

            .block:nth-child(3) {
                background: $dark_light;
                transform: translateX(-150%);
                animation: ani 1s forwards;

                transform: translateX(-150%);
                animation: ani 1s forwards;

                @keyframes ani {
                    0% {
                        transform: translateX(-150%);
                    }

                    100% {
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}


@import "media/_testirovanie.scss";