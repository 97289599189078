@media (max-width: 1366px) {
    .allstat {
        background-color: $black;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        font-family: PLAY-REGULAR;

        .title_page {
            display: flex;
            font-size: 30px;
            color: $light;
            text-align: center;
            margin-top: 190px;
            padding-bottom: 20px;
            justify-content: center;
        }

        .tablegoal {
            flex-direction: column;
            width: 100%;
            display: flex;
            font-size: 16px;
            padding-left: 10px;
            padding-right: 10px;

            .statistic:nth-child(1) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 80%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(2) {
                background-color: $gray_black;
                margin-bottom: 10px;
            }

            .statistic:nth-child(3) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 80%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(4) {
                background-color: $gray_black;
                margin-bottom: 10px;

                .wrapper {
                    width: 80%;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(5) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 80%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }

            }

            .statistic {
                display: flex;
                justify-content: center;
                align-items: start;
                padding: 5px 10px;
                color: $light;
                text-align: center;
                width: 100%;
                border-radius: 6px;

                .wrapper {
                    width: 80%;
                    padding-bottom: 10px;

                    .title {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 30px;
                        font-size: 30px;
                        font-family: PLAY-BOLD;
                        height: 75px;
                        padding-top: 20px;
                    }

                    ul {
                        font-family: PLAY-REGULAR;
                        height: 90%;

                        li {
                            display: flex;
                            padding: 5px 0;
                            justify-content: space-between;

                            .number {
                                display: flex;
                                justify-content: flex-start;
                                width: 20px;
                                font-size: 27px;
                                padding-left: 10px;
                            }

                            .player {
                                display: flex;
                                font-size: 27px;
                            }

                            .counter {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }

                            .total {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }
                        }

                        li:nth-child(odd) {
                            background-color: $gray_light_50;
                        }

                        li:hover {
                            background-color: $black;
                            color: $light;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .allstat {
        background-color: $black;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        font-family: PLAY-REGULAR;

        .title_page {
            display: flex;
            font-size: 30px;
            color: $light;
            text-align: center;
            margin-top: 190px;
            padding-bottom: 20px;
            justify-content: center;
        }

        .tablegoal {
            flex-direction: column;
            width: 100%;
            display: flex;
            font-size: 16px;
            padding-left: 10px;
            padding-right: 10px;

            .statistic:nth-child(1) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(2) {
                background-color: $gray_black;
                margin-bottom: 10px;
            }

            .statistic:nth-child(3) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(4) {
                background-color: $gray_black;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(5) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic {
                display: flex;
                justify-content: center;
                align-items: start;
                padding: 5px 10px;
                color: $light;
                text-align: center;
                width: 100%;
                border-radius: 6px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;

                    .title {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 30px;
                        font-size: 30px;
                        font-family: PLAY-BOLD;
                        height: 75px;
                        padding-top: 20px;
                    }

                    ul {
                        font-family: PLAY-REGULAR;
                        height: 90%;

                        li {
                            display: flex;
                            padding: 5px 0;
                            justify-content: space-between;

                            .number {
                                display: flex;
                                justify-content: flex-start;
                                width: 20px;
                                font-size: 27px;
                                padding-left: 10px;
                            }

                            .player {
                                display: flex;
                                font-size: 27px;
                            }

                            .counter {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }

                            .total {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }
                        }

                        li:nth-child(odd) {
                            background-color: $gray_light_50;
                        }

                        li:hover {
                            background-color: $black;
                            color: $light;
                            border-radius: 3px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .allstat {
        background-color: $black;
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        font-family: PLAY-REGULAR;

        .title_page {
            display: flex;
            font-size: 30px;
            color: $light;
            text-align: center;
            margin-top: 80px;
            padding-bottom: 20px;
            justify-content: center;
        }

        .tablegoal {
            flex-direction: column;
            width: 100%;
            display: flex;
            font-size: 16px;
            padding-left: 10px;
            padding-right: 10px;

            .statistic:nth-child(1) {
                background-color: $light;
                margin-bottom: 10px;
                width: 100%;

                .wrapper {
                    width: 100%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(2) {
                background-color: $gray_black;
                margin-bottom: 10px;
            }

            .statistic:nth-child(3) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(4) {
                background-color: $gray_black;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    border-radius: 3px;
                }
            }

            .statistic:nth-child(5) {
                background-color: $light;
                margin-bottom: 10px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;
                    color: $black;
                    border-radius: 3px;
                }
            }

            .statistic {
                display: flex;
                justify-content: center;
                align-items: start;
                padding: 5px 10px;
                color: $light;
                text-align: center;
                width: 100%;
                border-radius: 6px;

                .wrapper {
                    width: 90%;
                    padding-bottom: 10px;

                    .title {
                        display: flex;
                        justify-content: center;
                        padding-bottom: 30px;
                        font-size: 25px;
                        font-family: PLAY-BOLD;
                        height: 75px;
                        padding-top: 20px;
                    }

                    ul {
                        font-family: PLAY-REGULAR;
                        height: 90%;
                        width: 100%;

                        li {
                            display: flex;
                            padding: 5px 0;
                            justify-content: space-between;

                            .number {
                                display: flex;
                                justify-content: flex-start;
                                width: 20px;
                                font-size: 27px;
                                padding-left: 10px;
                            }

                            .player {
                                display: flex;
                                font-size: 27px;
                            }

                            .counter {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }

                            .total {
                                display: flex;
                                justify-content: flex-end;
                                width: 30px;
                                font-size: 27px;
                                padding-right: 10px;
                            }
                        }

                        li:nth-child(odd) {
                            background-color: $gray_light_50;
                        }

                        li:hover {
                            background-color: $black;
                            color: $light;
                            border-radius: 3px;
                        }
                    }
                }
            }

            .statistic-lost {
                padding-left: 0px;
                padding-right: 0px;
                font-size: 1svh;
            }
        }
    }
}