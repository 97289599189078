@media (max-width: 1366px) {
    .archive {
        padding: 170px 10px 10px 10px;

        .container_9 {
            flex-direction: column;
            height: 1500px;
            margin: 0px 0px 10px 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_8 {
            flex-direction: column;
            height: 100%;
            margin: 0px 00px 0px 00px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .big_block2 {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }
        }

        .container_1 {
            flex-direction: column;
            height: 1500px;
            margin: 0px 0px 10px 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_2 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_3 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_4 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_5 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_6 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }

        .container_7 {
            flex-direction: column;
            height: 1500px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 310px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1000px;
                margin: 0px 0px 0px 0px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 500px;

                    .text {
                        padding-top: 355px;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .archive {
        padding: 160px 10px 10px 10px;

        .container_9 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_8 {
            flex-direction: column;
            height: 100%;
            margin: 0px 00px 0px 00px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .big_block2 {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }
        }

        .container_1 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_2 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }

                }
            }
        }

        .container_3 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_4 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }

                }
            }
        }

        .container_5 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }

                }
            }
        }

        .container_6 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_7 {
            flex-direction: column;
            height: 2000px;
            margin-bottom: 0px;

            .big_block {
                width: 100%;
                margin-bottom: 10px;

                .text {
                    padding-top: 300px;
                    padding-left: 20px;
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 1500px;

                .medium_block {
                    height: 500px;

                    .text {
                        padding-top: 325px;
                        padding-left: 20px;
                    }
                }

                .small_blocks {
                    height: 1000px;
                    flex-direction: column;

                    .small_block1 {
                        height: 500px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 500px;
                        margin: 0px;

                        .text {
                            padding-top: 325px;
                            padding-left: 20px;

                            .title {
                                font-size: 40px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: 768px) {
    .archive {
        padding: 70px 10px 10px 10px;

        .container_9 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_8 {
            flex-direction: column;
            height: 100%;
            margin: 0px 00px 0px 00px;

            .big_block {
                height: 250px;
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .big_block2 {
                height: 250px;
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }
        }

        .container_1 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_2 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_3 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_4 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 50px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 30px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_5 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 60px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 25px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 50px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 30px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;
                        text-align: center;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_6 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 60px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 25px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 60px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 25px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;
                            text-align: center;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        justify-content: left;

                        button {
                            display: inline-block;
                            text-decoration: none;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                            border-radius: 5px;
                            font-size: 24px;
                            padding: 10px 30px;
                            transition: all 0.4s ease;
                            font-family: PLAY-REGULAR;
                            margin: 20px 0px 0px 20px;
                        }

                        button:hover {
                            text-decoration: none;
                            background-color: #1c3a75;
                            color: #ffffff;
                            border-color: #000000;
                            font-family: PLAY-REGULAR;
                        }
                    }
                }
            }
        }

        .container_7 {
            height: 100%;

            .big_block {
                height: 250px;

                .text {
                    padding-top: 60px;
                    padding-left: 10px;
                    padding-right: 10px;
                    justify-content: center;

                    .title {
                        font-size: 25px;
                        justify-content: center;
                    }
                }

                .button {
                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    view button {
                        margin: 10px 10px 0px 10px;
                    }
                }
            }

            .container_blocks {
                width: 100%;
                padding-left: 0px;
                height: 100%;

                .medium_block {
                    height: 250px;
                    min-width: 0px;

                    .text {
                        padding-top: 60px;
                        padding-left: 10px;
                        padding-right: 10px;
                        justify-content: center;

                        .title {
                            font-size: 25px;
                            justify-content: center;
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;
                        text-align: center;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }

                .small_blocks {
                    height: 100%;
                    flex-direction: column;

                    .small_block1 {
                        height: 250px;
                        justify-content: center;
                        text-align: center;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .small_block2 {
                        height: 250px;
                        margin: 0px;

                        .text {
                            padding-top: 50px;
                            padding-left: 10px;
                            padding-right: 10px;
                            justify-content: center;

                            .title {
                                font-size: 30px;
                                justify-content: center;
                            }
                        }

                        .button {
                            display: flex;
                            flex-direction: column;

                            button {
                                margin: 10px 10px 0px 10px;
                            }
                        }
                    }

                    .button {
                        display: flex;
                        flex-direction: column;

                        button {
                            margin: 10px 10px 0px 10px;
                        }
                    }
                }
            }
        }
    }
}