.alumnus {
    display: flex;
    width: 100%;
    background-color: $black;
    justify-content: center;
    padding: 190px 0 60px 0;

    .about_alumnus {
        display: flex;

        .about_alumnus_wrapper {
            text-align: center;
            width: 100%;
            margin: 50px 20px;
            line-height: 22px;
            display: flex;

            .index_text_block {
                width: 42%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                margin-left: 50px;
                padding-right: 40px;


                .title {
                    font-size: 45px;
                    font-family: PLAY-BOLD;
                    margin-bottom: 35px;
                    text-align: left;
                    color: $dark_light;
                }

                .text {
                    font-size: 22px;
                    font-family: PLAY-REGULAR;
                    text-align: start;
                    color: $light;
                    padding-bottom: 40px;
                }

                .text_background {
                    background-color: #00509D;
                    color: $light;
                    text-align: center;
                    font-family: PLAY-REGULAR;
                    font-size: 22px;
                    align-items: center;
                    height: 100px;
                    justify-content: center;
                }
            }

            .index_photo_block {
                display: flex;
                flex-direction: column;
                width: 58%;

                .big_photo {
                    width: 100%;
                    padding: 5px 20px 10px 20px;

                    img {
                        width: 100%;
                        border-radius: 3px;
                        aspect-ratio: 16/11;
                    }
                }

                .smalll_photo {
                    width: 100%;
                    display: flex;
                    padding: 10px 20px 20px 20px;
                    border-radius: 3px;

                    img {
                        aspect-ratio: 16/11;
                        width: 50%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }

                    :last-child {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}


@import "media/_alumnus.scss";